
import { api } from ".";

/*API calls for projects */
export const getProjects = () => {
    return api.get("projects");
}

export const createProject = (data:any) => {
    return api.post("projects",data);
}
export const getProjectById = (id:any) => {
    return api.get(`projects/${id}`);
}
export const updateProjectById = (data:any) => {
    return api.patch(`projects/${data.id}`,data.data);
}
export const deleteProjectById = (id:any) => {
    return api.delete(`projects/${id}`);
}