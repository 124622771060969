import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  createCategory,
  deleteCategoryById,
  getCategories,
  getCategoryById,
  updateCategoryById
} from "@services/categories.service";

interface CategoriesState extends ICategoriesResponseData {
  // Additional state properties if needed
}

const initialState: CategoriesState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  categories: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  categoryByID: {},
};

export const getCategoriesThunk = createAsyncThunk(
  "categories/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCategories();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCategoryThunk = createAsyncThunk(
  "categories/createcategory",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createCategory(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCategoryByIdThunk = createAsyncThunk(
  "categories/getcategoryById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getCategoryById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategoryByThunk = createAsyncThunk(
  "categories/updatecategoryById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateCategoryById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCategoryByIdThunk = createAsyncThunk(
  "categories/deletecategoryById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteCategoryById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const CategoriesSlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoriesThunk.pending, (state: CategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      getCategoriesThunk.fulfilled,
      (state: CategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.categories = payload?.data;
        state.categoryByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getCategoriesThunk.rejected, (state: CategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createCategoryThunk.pending, (state: CategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      createCategoryThunk.fulfilled,
      (state: CategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.categoryByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createCategoryThunk.rejected, (state: CategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getCategoryByIdThunk.pending, (state: CategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      getCategoryByIdThunk.fulfilled,
      (state: CategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.categoryByID = payload.data;
        state.categories = [];
        state.errors = [];
      }
    );
    builder.addCase(getCategoryByIdThunk.rejected, (state: CategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });

    builder.addCase(updateCategoryByThunk.pending, (state: CategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      updateCategoryByThunk.fulfilled,
      (state: CategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateCategoryByThunk.rejected, (state: CategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteCategoryByIdThunk.pending, (state: CategoriesState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteCategoryByIdThunk.fulfilled,
      (state: CategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteCategoryByIdThunk.rejected, (state: CategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getCategoriesList = (state: { categories: CategoriesState }) => {
  return state.categories?.categories;
};
export const isCategoryCreated = (state: { categories: CategoriesState }) => {
  return state.categories?.isCreated;
};
export const isCategoryDeleted = (state: { categories: CategoriesState }) => {
  return state.categories?.isDeleted;
};
export const isCategoryUpdated = (state: { categories: CategoriesState }) => {
  return state.categories?.isUpdated;
};
export const getOneCategory = (state: { categories: CategoriesState }) => {
  return state.categories?.categoryByID;
};
export const getCategoriesErrors = (state: { categories: CategoriesState }) => {
  return state.categories?.errors
};