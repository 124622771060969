import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, DatePickerProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Upload, UploadProps } from 'antd';

import CustomEditor from '@components/custom-editor';
import style from "../training.module.css";
import { RcFile, UploadFile } from 'antd/es/upload';

type FieldType = {
    name?: string;
    description?: string;
    loc?: string;
    isAvailableForJoining?:boolean;
    date: string;
    image: string;
};
const TrainingInfo = ({ editorValue, setEditorValue, trainingById, setChoosedDate,setImage,isAvailable,setIsAvailable }: any) => {

    const { t } = useTranslation();

    const [title, setTitle] = useState<string>('');  
    const [location, setLocation] = useState<string>('');
    // const [isChecked, setIsChecked] = useState<boolean>(trainingById?.isAvailableForJoining || false);


    const BASE_URL = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {

    }, [title,  location, setChoosedDate])

    const onChange: DatePickerProps['onChange'] = (date: any, dateString: any) => {       
        setChoosedDate(new Date(dateString).toISOString());
    };

    const getEditorContent = useCallback((content: any) => {
        setEditorValue(content);

    }, [setEditorValue]);

    const props: UploadProps = {
        name: 'image',
        headers: {
            authorization: 'authorization-text',
        },
        accept: "image/*",
        beforeUpload: () => {
            return false;
        },
      onChange(info:{fileList: UploadFile[]}) {
        console.log(info.fileList,"file")
            if (info.fileList.length) {
                setImage(info.fileList[0].originFileObj as RcFile)
            }

        },
    }; 

    const handleCheckboxChange:any = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setIsChecked(e.target.checked);
        setIsAvailable(e.target.checked); // If you need to update any other state based on Checkbox value
    };
    console.log(isAvailable,"isavailable")
    return (
        <fieldset className={style.formGroup}>
            <legend>{t("TRAINING.TAB_ONE")}</legend>
            <Form.Item<FieldType>
                label={t("TRAINING.TITLE")}
                name="name"
                rules={[{ required: true, message: 'Please input training Title!' }]}
            >
                <Input onChange={(e) => { setTitle(e.target.value) }} />
            </Form.Item>

            <Form.Item<FieldType>
                label={t("TRAINING.DESCRIPTION")}
                name="description"
            >
                <CustomEditor content={editorValue} getEditorContent={getEditorContent} />
            </Form.Item>

            <Form.Item<FieldType>
                label={t("TRAINING.IMAGE")}
                name="image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
            >
                <div>
                    {trainingById?.image?.path ? <img src={`${BASE_URL}/${trainingById?.image?.path}`} className={style.image} alt={trainingById?.image?.path} /> : null}
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>{t("MAIN.UPLOAD")}</Button>
                    </Upload>
                </div>
            </Form.Item>
            <Form.Item<FieldType>
                label={t("TRAINING.LOCATION")}
                name="loc"
                rules={[{ required: true, message: 'Please input training location!' }]}
            >
                <Input onChange={(e) => { setLocation(e.target.value) }} />
            </Form.Item>
            <Form.Item
                label={t("TRAINING.IS_AVAILABLE")}
                name="isAvailableForJoining"
            >
                <Checkbox 
                    //   type="checkbox"
                      onChange={handleCheckboxChange}
                      checked={isAvailable}
                    />
            </Form.Item>
            
            <Form.Item<FieldType>
                label={t("TRAINING.DATE")}
                name="date"
                rules={[{ required: true, message: 'Please input training date!' }]}
            >
                <DatePicker onChange={onChange}  format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }}/>
            </Form.Item>
        </fieldset>
    )
}

export default TrainingInfo;