import React from 'react';
import { CategoryTable } from './category-table';

const Categories: React.FC = () => {

  return (
    <div>    
      <CategoryTable/>
    </div>
  );
};

export default Categories;