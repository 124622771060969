import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";


import { createSubCategory, deleteSubCategoryById, getSubCategories, getSubCategoryById, updateSubCategoryById } from "@services/sub-categories.service";

interface SubCategoriesState extends ISubCategoriesResponseData {
  // Additional state properties if needed
}

const initialState: SubCategoriesState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  subCategories: [],
  isSubCreated: false,
  isSubDeleted: false,
  isSubUpdated: false,
  subCategoryByID: {},
};

export const getSubCategoriesThunk = createAsyncThunk(
  "subcategories/getSubCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSubCategories();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSubCategoryThunk = createAsyncThunk(
  "subcategories/createsubcategory",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createSubCategory(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubCategoryByIdThunk = createAsyncThunk(
  "subcategories/getsubcategoryById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getSubCategoryById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSubCategoryByThunk = createAsyncThunk(
  "subcategories/updatesubcategoryById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateSubCategoryById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteSubCategoryByIdThunk = createAsyncThunk(
  "subcategories/deletesubcategoryById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteSubCategoryById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const SubCategoriesSlice = createSlice({
  name: "subcategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubCategoriesThunk.pending, (state: SubCategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      getSubCategoriesThunk.fulfilled,
      (state: SubCategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.subCategories = payload.data;
        state.subCategoryByID = {};
        state.isSubCreated = false;
        state.isSubDeleted = false;
        state.isSubUpdated = false;
      }
    );
    builder.addCase(getSubCategoriesThunk.rejected, (state: SubCategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createSubCategoryThunk.pending, (state: SubCategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      createSubCategoryThunk.fulfilled,
      (state: SubCategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isSubCreated = true;
        state.isSubDeleted = false;
        state.isSubUpdated = false;
        state.subCategoryByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createSubCategoryThunk.rejected, (state: SubCategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isSubCreated = false;
      state.isSubDeleted = false;
      state.isSubUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getSubCategoryByIdThunk.pending, (state: SubCategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      getSubCategoryByIdThunk.fulfilled,
      (state: SubCategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isSubCreated = false;
        state.isSubDeleted = false;
        state.isSubUpdated = false;
        state.subCategoryByID = payload.data;
        state.subCategories = [];
        state.errors = [];
      }
    );
    builder.addCase(getSubCategoryByIdThunk.rejected, (state: SubCategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isSubCreated = false;
      state.isSubDeleted = false;
      state.isSubUpdated = false;
    });

    builder.addCase(updateSubCategoryByThunk.pending, (state: SubCategoriesState) => {
      state.loading = true;
    });
    builder.addCase(
      updateSubCategoryByThunk.fulfilled,
      (state: SubCategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isSubCreated = false;
        state.isSubDeleted = false;
        state.isSubUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateSubCategoryByThunk.rejected, (state: SubCategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isSubCreated = false;
      state.isSubDeleted = false;
      state.isSubUpdated = false;

    });

    builder.addCase(deleteSubCategoryByIdThunk.pending, (state: SubCategoriesState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteSubCategoryByIdThunk.fulfilled,
      (state: SubCategoriesState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isSubCreated = false;
        state.isSubDeleted = true;
        state.isSubUpdated = false;
      }
    );
    builder.addCase(deleteSubCategoryByIdThunk.rejected, (state: SubCategoriesState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isSubCreated = false;
      state.isSubDeleted = false;
      state.isSubUpdated = false;
    });
  },
});

export const getSubCategoriesList = (state: { subCategories: SubCategoriesState }) => {
  return state.subCategories?.subCategories;
};
export const isSubCategoryCreated = (state: { subCategories: SubCategoriesState }) => {
  return state.subCategories?.isSubCreated;
};
export const isSubCategoryDeleted = (state: { subCategories: SubCategoriesState }) => {
  return state.subCategories?.isSubDeleted;
};
export const isSubCategoryUpdated = (state: { subCategories: SubCategoriesState }) => {
  return state.subCategories?.isSubUpdated;
};
export const getOneSubCategory = (state: { subCategories: SubCategoriesState }) => {
  return state.subCategories?.subCategoryByID;
};
export const getSubCategoriesErrors = (state: { subCategories: SubCategoriesState }) => {
  return state.subCategories?.errors
};