import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, DatePicker, DatePickerProps, Form, Input, Modal, Select } from "antd";

import {
    createEventThunk,
    getEventByIdThunk,
    getEventsErrors,
    getOneEvent,
    isEventCreated,
    isMEventUpdated,
    updateEventByThunk
} from "@store/slices/event/event";
import { createChatThunk, getChatList, getChatsThunk } from "@store/slices/chat/chat";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import CustomButton from "@components/custom-button";
import { ChatList } from "./chat_list";

type FieldType = {
    title?: string;
    description?: string;
    author?: string;
    location?: string;
    isScheduled?: boolean
    chatId?: string;
    timestamp?: string;
    name?: string;
};

const Event: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [chatForm] = Form.useForm();

    const [dateTime, setDateTime] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chatName, setChatName] = useState("");
    const [chatId, setChatId] = useState("");
    const [selectedChatID, setSelectedChatId] = useState("");

    const eventByID = useAppSelector(getOneEvent);
    const isCreated = useAppSelector(isEventCreated);
    const isUpdated = useAppSelector(isMEventUpdated);
    const eventsError = useAppSelector(getEventsErrors);
    const chatList = useAppSelector(getChatList);
 const { Option } = Select;
    const onChange: DatePickerProps['onChange'] = (dateString: any) => {
        setDateTime(dateString);
    };
    useEffect(() => {
        dispatch(getChatsThunk());
    }, [dispatch])
    useEffect(() => {

        if (id)
            dispatch(getEventByIdThunk(id));

    }, [dispatch, id])

    useEffect(() => {

        if (isCreated || isUpdated)
            navigate(`/events/`)
    }, [navigate, isCreated, isUpdated])

    useEffect(() => {
        form.setFieldsValue({
            title: eventByID?.data?.title,
            description: eventByID?.data?.description,
            author: eventByID?.data?.author,
            location: eventByID?.data?.location,
            isScheduled: eventByID?.data?.isScheduled,
            chatId: eventByID?.data?.chatId
        });
    }, [form, eventByID]);

    const handleChange = (value: string) => {
        setSelectedChatId(value);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        dispatch(createChatThunk({
            chatId: chatId,
            name: chatName
        }))
        setChatName(""); // Clear the chat name state
        setChatId("");
        chatForm.resetFields();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = (values: any) => {
      
        const datas: any = {
            data: {
                "title": values.title,
                "description": values.description,
                "author": values.author,
                "location": values.location,
                "isScheduled": values.isScheduled || false,
                "chatId": selectedChatID ? selectedChatID : eventByID?.data?.chatId,
                "timestamp": dateTime

            },
            "id": id && id,
        }
        id ? dispatch(updateEventByThunk(datas)) : dispatch(createEventThunk(datas.data))
    };

    return (
        <>
            <h3>{id ? t("MAIN.EDIT_EVENT") : t("MAIN.ADD_EVENT")}</h3>
            <CustomButton title={t("MAIN.BACK")} to={"/events"} />
            <Form
                name="events"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                {eventsError ? eventsError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`EVENT.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                }
                <Form.Item<FieldType>
                    label={t("EVENT.TITLE_LABEL")}
                    name="title"
                    rules={[{ required: true, message: t("EVENT.TITLE_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={t("EVENT.DESCRIPTION_LABEL")}
                    name="description"
                    rules={[{ required: true, message: t("TEAM.DESCRIPTION_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("EVENT.AUTHOR_LABEL")}
                    name="author"
                    rules={[{ required: true, message: t("EVENT.AUTHOR_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("EVENT.LOCATION_LABEL")}
                    name="location"
                    rules={[{ required: true, message: t("EVENT.LOCATION_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("EVENT.IS_SCHEDULED_LABEL")}
                    name="isScheduled"

                // rules={[{ required: true, message: t("EVENT.IS_SCHEDULED_REQUIRED") }]}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("EVENT.CHAT_ID")}
                    name="chatId"
                // rules={[{ required: true, message: t("EVENT.CHAT_ID_REQUIRED") }]}

                >

                    {/* <Select
                        value={selectedChatID || eventByID?.data?.chatId}
                        style={{ width: "95%", marginRight: "10px" }}
                        onChange={handleChange}
                        options={chatList.map((chat: any) => ({
                            value: chat.chatId,
                            label: chat.name,
                        }))}

                    /> */}
                    <Select
                        value={selectedChatID}
                        style={{ width: "100%" }}
                        onChange={handleChange}
                    >
                        {chatList.map((chat: any) => (
                            <Option key={`${chat.id}`} value={chat.chatId}>
                                {chat.name}
                            </Option>
                        ))}
                    </Select>
                    <PlusCircleOutlined onClick={showModal} />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("EVENT.DATE_TIME")}
                    name="timestamp"
                    rules={[{ required: true, message: t("EVENT.DATE_TIME_REQUIRED") }]}
                >
                    <DatePicker onChange={onChange} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="actionButton">
                        {t("MAIN.SAVE")}
                    </Button>
                </Form.Item>
            </Form>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Add" >

                <ChatList />
                <Form
                    name="chat"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 800 }}
                    autoComplete="off"
                    form={chatForm}
                >
                    {/* {eventsError ? eventsError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`EVENT.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                } */}
                    <Form.Item<FieldType>
                        label={t("EVENT.CHAT_NAME")}
                        name="name"
                        rules={[{ required: true, message: t("EVENT.CHAT_NAME_REQUIRED") }]}
                    >
                        <Input onChange={(e: any) => { setChatName(e.target.value) }} />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={t("EVENT.CHAT_ID")}
                        name="chatId"
                        rules={[{ required: true, message: t("EVENT.CHAT_ID_REQUIRED") }]}
                    >
                        <Input onChange={(e: any) => { setChatId(e.target.value) }} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
export default Event;