import _ from "lodash";
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { deleteStudentByIdThunk, getStudentsList, getStudentsThunk, isStudentDeleted } from '@store/slices/student/student';
import { exportStudents } from '@services/students.services';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';

const Students = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const { id } = useParams<any>();

    const isDeleted = useAppSelector(isStudentDeleted);
    const studentList = useAppSelector<any>(getStudentsList);

    const [open, setOpen] = useState<boolean>(false);
    const [studentId, setStudentId] = useState<string | number>();
   


    useEffect(() => {
            if(id)
        dispatch(getStudentsThunk({id,type:"application/json"}));       

    }, [dispatch,id])

    useEffect(() => {

        if (isDeleted)
            dispatch(getStudentsThunk({id,type:"application/json"}));

    }, [dispatch, isDeleted, id])


    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };

    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, 
        total: studentList?.length 
    };

    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setStudentId(id);
    };
    const handleDeleteOk = () => {
        setOpen(false);
        dispatch(deleteStudentByIdThunk(studentId))
    }
    const handleDeleteCancel = () => setOpen(false)  

    const generateColumnsAndDataSource = (data: any) => {
        if (!data || data.length === 0) return { columns: [], dataSource: [] };

        let columns = [];
        let dataSource = [];
       
        for (let key in data[0].data) {
            columns.push({
                title: _.capitalize(key),
                dataIndex: `data.${key}`,
                key: key,
                render: (text: any) => <span>{text}</span> 
            });
        }

        dataSource = data.map((item: any) => {
            let dataObj: any = {};
            for (let key in item.data) {
                dataObj[`data.${key}`] = item.data[key];
            }
            return { ...dataObj, key: item.id }; 
        });
       
        columns.push({
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => {             
                return <Space size="middle">
                    <span onClick={() => { navigate(`/students/${record.key}`) }} className='link'>{t("MAIN.VIEW")}</span>
                    <span onClick={() => showModalDelete(record.key)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            },
        });

        return { columns, dataSource };
    };
  
    const { columns, dataSource } = generateColumnsAndDataSource(studentList);

    const handleExport = async () => {
        try {
            if(id){
            const blob = await exportStudents(id); 
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'students.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            }
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    return (
        <>
            <h3>{t("MAIN.STUDENTS")}</h3>
            {studentList?.length > 0 ? <>
                <button onClick={handleExport}>{t('MAIN.EXPORT')}</button>                     
                <AdminTable columns={columns} dataSource={dataSource} pagination={paginationConfig} />
            </>
                : null}
            <CustomModal title={t("MAIN.DELETE_MESSAGE")} open={open} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} />
        </>

    )
};

export default Students;