import { Button, Form, Input } from 'antd';
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    createCareerThunk,
    getCareerByIdThunk,
    getCareersErrors,
    getOneCareer,
    isCareerCreated,
    isCareerUpdated,
    updateCareerByThunk
} from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import CustomButton from "@components/custom-button";
import CustomEditor from '@components/custom-editor';


type FieldType = {
    title?: string;
    description?: string;
    shortDescription?: string;
};

const Career = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();

    const careerByID = useAppSelector(getOneCareer);
    const isCreated = useAppSelector(isCareerCreated);
    const isUpdated = useAppSelector(isCareerUpdated);
    const careersError = useAppSelector(getCareersErrors);

    const [editorValue, setEditorValue] = useState<string>("");

    useEffect(() => {

        if (id)
            dispatch(getCareerByIdThunk(id))
    }, [dispatch, id])

    useEffect(() => {

        if (isCreated || isUpdated)
            navigate(`/careers/`)

    }, [navigate, isCreated, isUpdated])

    useEffect(() => {
        setEditorValue(careerByID?.description)
        form.setFieldsValue({
            title: careerByID?.title,
            description: careerByID?.description,
            shortDescription: careerByID?.shortDescription
        });
    }, [form, careerByID]);   

    const getEditorContent = useCallback((content: any) => {
        setEditorValue(content);
    }, []);

    const onFinish = (values: any) => {
        const datas: any = {
            data: {
                "title": values.title,
                "description": editorValue,
                "shortDescription": values.shortDescription
            },
            "id": id && id,
        }

        id ? dispatch(updateCareerByThunk(datas)) : dispatch(createCareerThunk(datas.data));

    };

    return (
        <>
            <h3>{id ? t("MAIN.EDIT_CAREER") : t("MAIN.ADD_CAREER")}</h3>
            <CustomButton title={t("MAIN.BACK")} to={"/careers"} />
            <Form
                name="career"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}               
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                {careersError ? careersError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`CAREER.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                }
                <Form.Item<FieldType>
                    label={t("CAREER.TITLE_LABEL")}
                    name="title"
                    rules={[{ required: true, message: t("CAREER.TITLE_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={t("CAREER.DESCRIPTION_LABEL")}
                    name="description"
                >
                    <CustomEditor content={editorValue} getEditorContent={getEditorContent} />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("CAREER.SHORT_DESCRIPTION_LABEL")}
                    name="shortDescription"
                    rules={[{ required: true, message: t("CAREER.SHORT_DESCRIPTION_REQUIRED") }]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="actionButton">
                        {t("MAIN.SAVE")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
export default Career;