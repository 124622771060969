import { RcFile } from 'antd/es/upload';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Button, Form, Input, Upload, UploadProps } from 'antd';

import { createArticleThunk,
     getArticleByIdThunk,
     getArticlesErrors, 
     getOneArticle, 
     isArticleCreated, 
     isArticleUpdated, 
     updateArticleByThunk } from '@store/slices';
import {getCategoriesThunk } from '@store/slices/categories/categories';
import SelectList from '@pages/category/components/select-list';
import CustomButton from "@components/custom-button";
import CustomEditor from '@components/custom-editor';
import style from "./article.module.css";


type FieldType = {
    title?: string;
    description?: string;
    author?: string;
    category?: string;
    image?: string;
};

const Article = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params: any = useParams();
    const [form] = Form.useForm();
    const BASE_URL = process.env.REACT_APP_IMAGE_URL;

    const articleByID = useAppSelector(getOneArticle); 
    const isCreated = useAppSelector(isArticleCreated);
    const isUpdated = useAppSelector(isArticleUpdated);
    const articlesError = useAppSelector(getArticlesErrors);

    const [image, setImage] = useState<any>();
    const [categoryId, setCategoryId] = useState(articleByID?.category?.id);
    const [editorValue, setEditorValue] = useState<string>("");

    useEffect(() => {
        dispatch(getCategoriesThunk());
        if (params.id)
            dispatch(getArticleByIdThunk(params.id))

    }, [dispatch,params.id])

    useEffect(() => {

        if (isCreated || isUpdated)
            navigate(`/articles/`)
    }, [navigate,isCreated, isUpdated])

    useEffect(() => {

        form.setFieldsValue({
            title: articleByID?.title,
            description: articleByID?.description ,
            author: articleByID?.author,
            category: articleByID?.category?.id,
            image: articleByID?.image?.path
        });
    }, [form,articleByID]);

      const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        accept: "image/*",
        beforeUpload: () => {
            return false;
        },
        async onChange(info) {
            const { file } = info;

            if (info.fileList.length) {            
                setImage(file as RcFile)
            }

        },

    };
    
    const getEditorContent = useCallback((content: any) => {
        setEditorValue(content);   
    }, [setEditorValue]);

    const onFinish = (values: any) => {

        const datas: any = {
            data: {
                "title": values.title,
                "description": editorValue,
                "author": values.author,
                "categoryId": categoryId,
                "file": image
            },
            "id": params.id && params.id,
        }

        params.id ? dispatch(updateArticleByThunk(datas)) : dispatch(createArticleThunk(datas.data));

    };

    return (
        <>
            <h3>{params.id ? t("MAIN.EDIT_ARTICLE") : t("MAIN.ADD_ARTICLE")}</h3>
            <CustomButton title={t("MAIN.BACK")} to={"/articles"} />
            <Form
                name="article"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}               
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                {articlesError ? articlesError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`ARTICLE.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                }
                <Form.Item<FieldType>
                    label={t("ARTICLE.TITLE_LABEL")}
                    name="title"
                    rules={[{ required: true, message: t("ARTICLE.TITLE_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={t("ARTICLE.DESCRIPTION_LABEL")}
                    name="description"
                    // rules={[{ required: true, message: t("ARTICLE.DESCRIPTION_REQUIRED") }]}
                >
                    <CustomEditor content={editorValue || articleByID?.description} getEditorContent={getEditorContent} />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("ARTICLE.AUTHOR_LABEL")}
                    name="author"
                    rules={[{ required: true, message: t("ARTICLE.AUTHOR_REQUIRED")}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("ARTICLE.CATEGORY_LABEL")}
                    name="category"
                    // rules={[{ required: true, message:  t("ARTICLE.CATEGORY_REQUIRED") }]}
                > <SelectList setCategoryId={setCategoryId} selectedCategoryId ={articleByID?.category?.id}/>
                </Form.Item>             
                <Form.Item<FieldType>
                    label={t("ARTICLE.IMAGE_LABEL")}
                    name="image"
                    rules={[{ required: true, message: t("ARTICLE.IMAGE_REQUIRED") }]}
                >
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>{t("MAIN.UPLOAD")}</Button>
                    </Upload>
                    {articleByID?.image?.path ? <img src={`${BASE_URL}/${articleByID?.image?.path}`} className={style.image} alt ={articleByID?.image?.path} /> : null}

                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="actionButton">
                        {t("MAIN.SAVE")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
export default Article;