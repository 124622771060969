import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import { deleteApplicantByIdThunk, getApplicantsList, getApplicantsThunk, isApplicantDeleted } from '@store/slices';
import { IApplicantResponseData } from '@models/interfaces/applicant';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DataType } from '@models/interfaces/table';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';

const Applicants: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const { id } = useParams<any>();
    const isDeleted = useAppSelector(isApplicantDeleted);
    const applicantList = useAppSelector<IApplicantResponseData[]>(getApplicantsList);

    const [open, setOpen] = useState<boolean>(false);
    const [applicantId, setApplicantId] = useState<string | number>();

    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: applicantList.length // Assuming total count is available in trainingList
    };
    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setApplicantId(id);
    };
    const handleDeleteOk = () => {
        setOpen(false);
        dispatch(deleteApplicantByIdThunk(applicantId))
    }
    const handleDeleteCancel = () => setOpen(false)

    useEffect(() => {

        dispatch(getApplicantsThunk(id));

    }, [dispatch, id])

    useEffect(() => {

        if (isDeleted)
            dispatch(getApplicantsThunk(id));

    }, [dispatch, isDeleted, id])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',
            render: (_, record, index) => {
                return index + 1;
            }
        },
        {
            title: t("APPLICANT.NAME"),
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: t("APPLICANT.EMAIL"),
            dataIndex: 'email',
            key: 'email',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t("APPLICANT.PHONE"),
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t("APPLICANT.ADDRESS"),
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: t("APPLICANT.CAREER_TITLE"),
            dataIndex: 'vacancyId',
            key: 'vacancyId',
            render: (_, record: any) => <span>{record.vacancy.title}</span>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/applicants/${record.id}`) }} className='link'>{t("MAIN.VIEW")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];

    return (
        <>
            <h3>{t("MAIN.APPLICANTS")}</h3>
            {applicantList ? <AdminTable columns={columns} dataSource={applicantList} pagination={paginationConfig}/> : "null"}
            <CustomModal title={t("MAIN.DELETE_MESSAGE")} open={open} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} />
        </>

    )
};

export default Applicants;