import { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';

import {
    createCategoryThunk,
    getCategoriesErrors,
    getCategoriesList,
    getCategoryByIdThunk,
    getOneCategory,
    isCategoryCreated,
    isCategoryUpdated,
    updateCategoryByThunk,   
} from '@store/slices';
import CustomButton from "@components/custom-button";
import SelectList from './components/select-list';

type FieldType = {
    name?: string;
    key?: string;
    subCategories?: string;
};

const Category = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params: any = useParams();
    // const isSubCategoryPage =  location.pathname.startsWith("/categories/sub-category");
    const [form] = Form.useForm();
    const [categoryId, setCategoryId] = useState();

    const categoryByID = useAppSelector(getOneCategory);
    // const subCategoryByID = useAppSelector(getOneSubCategory);
    const categoriesList = useAppSelector(getCategoriesList);
    const isCreated = useAppSelector(isCategoryCreated);
    const isUpdated = useAppSelector(isCategoryUpdated);
    // const isSubCreated = useAppSelector(isSubCategoryCreated);
    // const isSubUpdated = useAppSelector(isSubCategoryUpdated);
    const categoriesError = useAppSelector(getCategoriesErrors);

    useEffect(() => {
        // isSubCategoryPage && params.id
        //  ? dispatch(getSubCategoryByIdThunk(params.id))
        //  : dispatch(getCategoryByIdThunk(params.id))

        dispatch(getCategoryByIdThunk(params.id))

    }, [dispatch, params.id])

    useEffect(() => {
        if (isCreated || isUpdated )
            navigate(`/categories/`)
    }, [navigate, isCreated, isUpdated])

    useEffect(() => {
        // isSubCategoryPage ?
        // form.setFieldsValue({
        //     name: subCategoryByID?.name,
        //     key: subCategoryByID?.key,
        //     categoryByID:subCategoryByID.id
        // })
        // :
        form.setFieldsValue({
            name: categoryByID?.name,
            key: categoryByID?.key,
            categoryByID:categoryByID.id
        });
    }, [form, categoryByID]);

    const onFinish = (values: any) => {
        const categoryDatas: any = {
            data: {
                "name": values.name,
                "key": values.key,
                "parentId": categoryId

            },
            "id": params.id && params.id,
        }

        // const subCategoryDatas: any = {
        //     data: {
        //         "name": values.name,
        //         "key": values.key,
        //         "categoryId": categoryId

        //     },
        //     "id": params.id && params.id,
        // }

        // if (isSubCategoryPage) {
        //     params.id ? dispatch(updateSubCategoryByThunk(subCategoryDatas)) : dispatch(createSubCategoryThunk(subCategoryDatas.data));
        // } else {
            params.id ? dispatch(updateCategoryByThunk(categoryDatas)) : dispatch(createCategoryThunk(categoryDatas.data));
        // }

    };

    return (
        <>
            {/* {isSubCategoryPage ?
                <h3>{params.id ? t("MAIN.EDIT_SUB_CATEGORY") : t("MAIN.ADD_SUB_CATEGORY")}</h3>
                : */}
                <h3>{params.id ? t("MAIN.EDIT_CATEGORY") : t("MAIN.ADD_CATEGORY")}</h3>
            {/* } */}
            <CustomButton title={t("MAIN.BACK")} to={"/categories"} />
            <Form
                name="category"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                {categoriesError ? categoriesError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`CATEGORIES.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                }
                <Form.Item<FieldType>
                    label={t("CATEGORIES.NAME_LABEL")}
                    name="name"
                    rules={[{ required: true, message: t("CATEGORIES.NAME_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={t("CATEGORIES.KEY_LABEL")}
                    name="key"
                    rules={[{ required: true, message: t("CATEGORIES.KEY_REQUIRED") }]}
                >
                    <Input />
                </Form.Item>
                {/* {isSubCategoryPage ? ( */}
                    <Form.Item<FieldType>
                        label={t("MAIN.CATEGORIES")}
                        name="subCategories"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: t("CATEGORIES.NAME_REQUIRED"),
                    //     },
                    // ]}
                    >
                        <SelectList list={categoriesList} setCategoryId={setCategoryId} />
                    </Form.Item>
                {/* ) : null} */}


                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="actionButton">
                        {t("MAIN.SAVE")}
                    </Button>
                </Form.Item>
            </Form>
        </>

    );
}
export default Category;