import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { deleteStudentById, getStudentById, getStudents } from "@services/students.services";
import { IStudentResponseData } from "@models/interfaces/student";

interface StudentState extends IStudentResponseData {
  // Additional state properties if needed
}

const initialState: StudentState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  students: [],
  isDeleted: false,  
  studentByID: {},
  path:""
};

export const getStudentsThunk = createAsyncThunk(
  "student/getStudents",
  async (data:any, { rejectWithValue }) => {
    try {
      const response = await getStudents(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStudentByIdThunk = createAsyncThunk(
  "student/getStudentById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getStudentById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStudentByIdThunk = createAsyncThunk(
  "student/deleteStudentById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteStudentById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const StudentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudentsThunk.pending, (state: StudentState) => {
      state.loading = true;
    });
    builder.addCase(
      getStudentsThunk.fulfilled,
      (state: StudentState, { payload }: PayloadAction<any>) => {    
        state.loading = false;
        state.students = payload;
        state.studentByID = {};        
        state.isDeleted = false;        
      }
    );
    builder.addCase(getStudentsThunk.rejected, (state: StudentState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(getStudentByIdThunk.pending, (state: StudentState) => {
      state.loading = true;
    });
    builder.addCase(
      getStudentByIdThunk.fulfilled,
      (state: StudentState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;       
        state.isDeleted = false;     
        state.studentByID = payload.data;
        state.students = [];    
      }
    );
    builder.addCase(getStudentByIdThunk.rejected, (state: StudentState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;    
      state.isDeleted = false;    
    });

    builder.addCase(deleteStudentByIdThunk.pending, (state: StudentState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteStudentByIdThunk.fulfilled,
      (state: StudentState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;       
        state.isDeleted = true;       
      }
    );
    builder.addCase(deleteStudentByIdThunk.rejected, (state: StudentState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;     
      state.isDeleted = false;     
    });
  },
});

export const getStudentsList = (state: { students: StudentState }) => {
  return state.students?.students;
};
export const isStudentDeleted = (state: { students: StudentState }) => {
  return state.students?.isDeleted;
};

export const getOneStudent = (state: { students: StudentState }) => {
  return state.students?.studentByID;
};
export const getStudentsErrors = (state: { students: StudentState }) => {
  return state.students?.errors
};
export const getPath =  (state: { students: StudentState }) => {
  return state.students?.path
};