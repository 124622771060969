import dayjs from 'dayjs';
import moment from 'moment';
import type { TabsProps } from 'antd';
import { Button, Form, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
  createTrainingThunk,
  getOneTraining,
  getTrainingByIdThunk,
  updateTrainingByThunk,
} from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import TrainingInfo from './training-info';
import Trainings from './trainings';
import { useTranslation } from 'react-i18next';
import Students from '@pages/students';

const TrainingsTabs: React.FC = () => {
  const params: any = useParams();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const trainingById = useAppSelector(getOneTraining);

  const [editorValue, setEditorValue] = useState<string>("");
  const [choosedDate, setChoosedDate] = useState<any>();
  const [showValidationState, setShowValidationState] = useState<any>({});
  const [selectedTypes, setSelectedTypes] = useState<{ [key: string]: string }>({});
  const [activeTab, setActiveTab] = useState<string>('1');
  const [image, setImage] = useState<any>();
  const [isAvailable, setIsAvailable] = useState<any>();

  useEffect(() => {
    if (params.id) dispatch(getTrainingByIdThunk(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    setIsAvailable(trainingById?.isAvailableForJoining || false)
  }, [trainingById])

  useEffect(() => {
    if (trainingById) {
      form.setFieldsValue({
        name: trainingById?.name,
        description: trainingById?.description,
        loc: trainingById?.location,
        image: trainingById?.image?.path,
        date: dayjs(trainingById?.date).subtract(4, 'hours'), //dayjs(trainingById?.date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        isAvailableForJoining: trainingById?.isAvailableForJoining,
        training: trainingById?.data?.map((field: any) => {
          setShowValidationState(field.isShowValidation);

          const selectValue = field.validations ?
            field.validations.find((validation: any) => validation.valid)?.valid || ''
            : "";

            const extValue = field.validations ?
            field.validations.find((validation: any) => validation.ext)?.ext.join() || ''
            : "";

          return {
            name: field.field,
            type: field.type,
            placeholder: field.placeholder,
            select: selectValue,
            required: field.validations ? field.validations.find((validation: any) => validation.required)?.required[0] === 'true' : "",
            min: field.validations ? field.validations.find((validation: any) => validation.min)?.min[0] : "",
            max: field.validations ? field.validations.find((validation: any) => validation.max)?.max[0] : "",
            pattern: field.validations ? field.validations.find((validation: any) => validation.pattern)?.pattern[0] : "",
            showValidation: field.isShowValidation,
            size:field.validations ? field.validations.find((validation: any) => validation.size)?.size[0] : "",
            ext:extValue
          };
        }),
      });

      setChoosedDate(dayjs(trainingById?.date).subtract(4, 'hours'));
    }
  }, [form, trainingById]);

  const handleTypeChange = (name: any, value: string) => {
    setSelectedTypes((prevSelectedTypes) => ({ ...prevSelectedTypes, [name]: value }));
  };

  const handleCheck = (key: any, checked: any) => {
    setShowValidationState((prevShowValidation: any) => ({
      ...prevShowValidation,
      [key]: checked,
    }));
  };
  const onFinish = (values: any) => {
    const data: any[] = [];
    values.training.forEach((item: any, index: number) => {
      const fieldData: any = {
        field: item.name,
        type: item.type,
        placeholder: item.placeholder,
        order: index + 1,
        validations: [],
      };

      if (item.type === 'input') {
        fieldData.validations.push(
          { required: item.required ? [item.required] : void 0},
          { pattern: item.pattern ? [item.pattern] : []},
          { min: item.min ? [item.min] : void 0 },
          { max: item.max ? [item.max] : void 0}
        );
      } else if (item.type === 'select' || item.type === 'checkbox' || item.type === 'radio') {
        fieldData.validations.push(
          { valid: item.select ? item.select : []},
          { required: item.required ? [item.required] : void 0}
        );
      }
      else if (item.type === 'file') {
        fieldData.validations.push(   
          { required: item.required ? [item.required] : void 0 },
          { size: item.size ? [item.size] : void 0 },
          { ext: item.ext ? [item.ext] : [] }
        );
      }
      if (fieldData.validations.length > 0) {
        data.push(fieldData);
      }
    });
    
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", editorValue || trainingById.description);
    formData.append("location", values.loc);
    formData.append("date", moment(choosedDate).format("YYYY-MM-DD,HH:MM:SS"));
    formData.append("isAvailableForJoining", isAvailable);
    formData.append("file", image);

    data.forEach((item: any, index: any) => {
      formData.append(`data[${index}][field]`, item.field);
      formData.append(`data[${index}][type]`, item.type);
      formData.append(`data[${index}][placeholder]`, item.placeholder);
      formData.append(`data[${index}][order]`, item.order);
      formData.append(`data[${index}][isShowValidation]`, showValidationState[index]);

      item?.validations?.forEach((validation: any, validationIndex: any) => {
        for (const key in validation) {
          if (validation.hasOwnProperty(key)) {
            // if (key === "valid") {
            //     validation[key].forEach((element: any, i: any) => {
            //         formData.append(`data[${index}][validations][${validationIndex}][${key}][${i}]`, element);
            //     });
            // }
            if (key === "ext") {
              if(validation[key]){
                
              const spltData =validation[key][0].split(",");
              spltData.forEach((element: any, i: any) => {
                formData.append(`data[${index}][validations][${validationIndex}][${key}][${i}]`, element);
              })
            }
            }
            else {
                 if(validation[key])
              validation[key]?.forEach((element: any, i: any) => {
                formData.append(`data[${index}][validations][${validationIndex}][${key}][${i}]`, element);
              });
            }
          }
        }
      });
    });

    params.id ? dispatch(updateTrainingByThunk({ id: params.id, formData })) : dispatch(createTrainingThunk(formData));

  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t("TRAINING.TAB_ONE"),
      children: <TrainingInfo
        setImage={setImage}
        editorValue={editorValue || trainingById.description}
        setChoosedDate={setChoosedDate}
        setEditorValue={setEditorValue}
        trainingById={trainingById}
        isAvailable={isAvailable}
        setIsAvailable={setIsAvailable} />,
    },
    {
      key: '2',
      label: t("TRAINING.TAB_TWO"),
      children: <Trainings
        editorValue={editorValue}
        showValidationState={showValidationState || trainingById.data.isShowValidation}
        trainingById={trainingById}
        handleTypeChange={handleTypeChange}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        handleCheck={handleCheck}
        form={form}
        id={params.id} />,
    },
    ...(params.id ? [
      {
        key: '3',
        label: t("STUDENT.STUDENTS"),
        children: <Students />,
      },
    ] : [])
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  const handleNextButtonClick = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
  };
  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      style={{ maxWidth: 900, margin: '0 auto' }}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Tabs defaultActiveKey="1" activeKey={activeTab} items={items} onChange={onChange} />
      <div style={{ marginTop: '16px', display: "flex", justifyContent: "center  " }}>
        {activeTab === '1' && (
          <Button type="primary" onClick={handleNextButtonClick}>
            {t("TRAINING.NEXT")}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default TrainingsTabs;
