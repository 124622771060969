import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createEvent, deleteEventById, getEventById, getEvents, updateEventById } from "@services/event.services";


interface EventState extends IEventResponseData {
  // Additional state properties if needed
}

const initialState: EventState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  events: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  eventByID: {},
};

export const getEventsThunk = createAsyncThunk(
  "events/getEvents",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getEvents();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createEventThunk = createAsyncThunk(
  "events/createEvent",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createEvent(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEventByIdThunk = createAsyncThunk(
  "events/geteventById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getEventById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEventByThunk = createAsyncThunk(
  "events/updateEventById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateEventById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteEventByIdThunk = createAsyncThunk(
  "events/deleteEventById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteEventById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const EventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventsThunk.pending, (state: EventState) => {
      state.loading = true;
    });
    builder.addCase(
      getEventsThunk.fulfilled,
      (state: EventState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.events = payload.data;
        state.eventByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getEventsThunk.rejected, (state: EventState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createEventThunk.pending, (state: EventState) => {
      state.loading = true;
    });
    builder.addCase(
      createEventThunk.fulfilled,
      (state: EventState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.eventByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createEventThunk.rejected, (state: EventState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getEventByIdThunk.pending, (state: EventState) => {
      state.loading = true;
    });
    builder.addCase(
      getEventByIdThunk.fulfilled,
      (state: EventState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.eventByID = payload.data;
        state.events = [];
        state.errors = [];
      }
    );
    builder.addCase(getEventByIdThunk.rejected, (state: EventState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });


    builder.addCase(updateEventByThunk.pending, (state: EventState) => {
      state.loading = true;
    });
    builder.addCase(
      updateEventByThunk.fulfilled,
      (state: EventState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateEventByThunk.rejected, (state: EventState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteEventByIdThunk.pending, (state: EventState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteEventByIdThunk.fulfilled,
      (state: EventState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteEventByIdThunk.rejected, (state: EventState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getEventsList = (state: { event: EventState }) => {
  return state.event?.events;
};
export const isEventCreated = (state: { event: EventState }) => {
  return state.event?.isCreated;
};
export const isEventDeleted = (state: { event: EventState }) => {
  return state.event?.isDeleted;
};
export const isMEventUpdated = (state: { event: EventState }) => {
  return state.event?.isUpdated;
};
export const getOneEvent = (state: { event: EventState }) => {
  return state.event?.eventByID;
};
export const getEventsErrors = (state: { event: EventState }) => {
  return state.event?.errors
};