import { Modal } from "antd";

const CustomModal = ({ title, open, handleOk, handleCancel }: any) => {

    return (
        <Modal
            open={open}
            title={title}
            onOk={handleOk}
            onCancel={handleCancel}
        ></Modal>
    )
}

export default CustomModal;