
import { api } from ".";

/*API calls for Career */
export const getCareers = () => {
    return api.get("vacancies");
}
export const createCareer = (data:any) => {   
    return api.post("vacancies", data);
}
export const getCareerById = (id:any) => {
    return api.get(`vacancies/${id}`);
}
export const updateCareerById = (data:any) => {
    return api.patch(`vacancies/${data.id}`, data.data);
}
export const deleteCareerById = (id:any) => {
    return api.delete(`vacancies/${id}`);
}