
import { api } from ".";

/*API calls for Chats */
export const getChats = () => {
    return api.get("chats");
}
export const createChat = (data:any) => {     
    return api.post("chats", data);
}
export const getChatById = (id:any) => {
    return api.get(`chats/${id}`);
}
// export const updateEventById = (data:any) => {
//     return api.put(`events/${data.id}`,data.data);
// }
export const deleteChatById = (id:any) => {
    return api.delete(`chats/${id}`);
}