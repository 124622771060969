
import { getCookie } from "@utils/cookies-helper";
import { api } from ".";

/*API calls for Students */
export const getStudents = (data: any) => {
    return api.get(`/trainings/${data.id}/participants`, {
        headers: {
            'Accept': `${data.type}`
        }
    });
}

export const exportStudents = async (trainingId: string | number) => {
    const accessToken = getCookie("accessToken");
    try {
        const response = await fetch(`https://api.dev.padcllc.com/v1/trainings/${trainingId}/participants?authorization=${accessToken}`,
            {
                headers: {
                    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            });
        const blob = await response.blob();
        return blob;
    } catch (error) {
        throw new Error('Failed to export students');
    }
};

export const getStudentById = (id: any) => {
    return api.get(`trainings/participants/${id}`);
}
export const deleteStudentById = (id: any) => {
    return api.delete(`trainings/participants/${id}`);
}