import { getAccessToken } from "@store/slices";
import { useAppSelector } from "@hooks/index";
import AuthRoutes from "./auth-routes";
import MainRoutes from "./main-route";

const Routing: React.FC = () => {
 
  const token  = useAppSelector(getAccessToken);

  return (!(token) ? <AuthRoutes /> : <MainRoutes />);
};

export default Routing;
