import React from 'react';
import { debounce } from 'lodash';
import { DndProvider, useDrag, useDrop } from 'react-dnd';

import DynamicForm from './dynamic-form';
import { HTML5Backend } from 'react-dnd-html5-backend';

const MemoizedDynamicForm = React.memo(DynamicForm);

const DraggableDynamicForm = ({
  handleTypeChange,
  selectedTypes,
  handleCheck,
  showValidationState,
  form,
  index,
  remove,
  move,
  fields, 
  name

}: any) => {

  const [{ isDragging }, drag] = useDrag({
    type: 'DYNAMIC_FORM',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      
      setTimeout(() => {
        // Your end logic here
      }, 0);
    },
   
  });

  const debouncedHover = debounce((item: any, monitor: any) => {
    const dragIndex = item.index;
    const hoverIndex = index;

    if (dragIndex === hoverIndex) {
      return;
    }
    move(dragIndex, hoverIndex);
    item.index = dragIndex;
  }, 300);


  const [, drop] = useDrop({
    accept: 'DYNAMIC_FORM',
    hover: debouncedHover
  });

  const opacity = isDragging ? 0 : 1;
  const shadow = isDragging ? "5px 5px 10px #000":"0px 0px 0px transparent"
  const transitionStyles = {
    transition: 'opacity 0.3s ease, box-shadow 0.3s ease',
  };
  return (
    <DndProvider backend={HTML5Backend}>
    <div ref={(node) => drag(drop(node))} className="draggable-dynamic-form" style={{ ...transitionStyles, marginBottom: '8px', opacity,boxShadow:shadow, cursor: 'move' }}>

      <MemoizedDynamicForm       
        name={name}
        handleTypeChange={handleTypeChange}
        form={form}
        selectedTypes={selectedTypes}
        handleCheck={handleCheck}
        showValidationState={showValidationState}
        remove={remove}
        move={move}
        index={index}
        fields={fields}
      />
    </div>
    </DndProvider>
  );
};

export default DraggableDynamicForm;
