import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminLoginData, IAdminLoginResponseData } from "@models/interfaces/auth";
import { deleteCookie, getCookie, setCookie } from "@utils/cookies-helper";
import { ACCESS_TOKEN_DURATION } from "@utils/consts";
import { adminLogin } from "@services/auth.service";

interface AdminLoginState extends IAdminLoginResponseData {
  // Additional state properties if needed
}

const initialState: AdminLoginState = {
  success: false,
  message: "",
  statusCode: 0,
  statusName: "",
  loading: false,
  accessToken: getCookie("accessToken"),
  errors:[]
};

export const adminLoginThunk = createAsyncThunk(
  "admin/login",
  async (data: IAdminLoginData, { rejectWithValue }) => {
    try {
      const response = await adminLogin(data);
      setCookie("accessToken", response.data.accessToken, ACCESS_TOKEN_DURATION);    
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AdminLoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    adminLogout: (state) => {
      deleteCookie("accessToken", "");
      state.accessToken = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLoginThunk.pending, (state: AdminLoginState) => {
      state.loading = true;
    });
    builder.addCase(
      adminLoginThunk.fulfilled,
      (state: AdminLoginState, { payload }: PayloadAction<AxiosResponse<{ accessToken: string }>>) => {
        state.success = true;
        state.accessToken = payload.data.accessToken;
        state.loading = false;
      }
    );
    builder.addCase(adminLoginThunk.rejected, (state: AdminLoginState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
    });
  },
});

export const { adminLogout } = AdminLoginSlice.actions;

export const getAdminLoginErrorMessages = (state: { adminLogin: AdminLoginState }) => {
  return state.adminLogin?.message;
};
export const getAdminLoginErrors = (state: { adminLogin: AdminLoginState }) => {
  return state.adminLogin?.errors;
};
export const getAccessToken = (state: { adminLogin: AdminLoginState }) => {
  return state.adminLogin.accessToken;
};