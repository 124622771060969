import { useEffect } from 'react';
import { Button, Col, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import {
  getTrainingErrors,
  isTrainingCreated,
  isTrainingUpdated
} from '@store/slices';
import DraggableDynamicForm from './draggable-dynamic-form';
import { useAppSelector } from '@hooks/index';
import style from "../training.module.css";
import { t } from 'i18next';

const Trainings = ({
  handleTypeChange,
  selectedTypes,
  handleCheck,
  showValidationState,
  form }: any) => {

  const navigate = useNavigate();
  const errors = useAppSelector(getTrainingErrors);
  const isCreated = useAppSelector(isTrainingCreated);
  const isUpdated = useAppSelector(isTrainingUpdated);
  useEffect(() => {

    if (isCreated || isUpdated)
      navigate(`/trainings`)

  }, [navigate, isCreated, isUpdated])

  const renderErrors = () => {
    return errors?.map((errorText: any, index) => (
      <p key={index} className="error">
        {errorText.message}
      </p>
    ));
  };

  return (
    <>
      {renderErrors()}
      <fieldset className={style.formGroup} style={{padding:"25px"}}>
        <legend>Create Training form</legend>
        <Form.List name="training">
          {(fields: any, { add, remove, move }: any) => (
            <>
              {fields.map(({ key, name, ...restField }: any, index: number) => (
                <DraggableDynamicForm
                  key={key}
                  restField={restField}
                  name={name}
                  handleTypeChange={handleTypeChange}
                  form={form}
                  selectedTypes={selectedTypes}
                  handleCheck={handleCheck}
                  showValidationState={showValidationState}
                  remove={remove}
                  move={move}
                  index={index}
                  fields={fields} />

              ))}
              <Col span={24}>
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {t("TRAINING.ADD_FORM_FIELDS")}
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
          {t("MAIN.SAVE")}
          </Button>
        </Form.Item>
      </fieldset>
    </>
  );
};

export default Trainings;