import { Editor } from '@tinymce/tinymce-react';

interface IProps {
    content?: string;
    getEditorContent: Function;
    count?: any;
    currentLngId?: number;
    isDisabled?: boolean;
    article?: true;
    articleForm?: any;
}

const CustomEditor = ({ content, getEditorContent }: IProps): JSX.Element => {

    return (
        <div>           
            <Editor      
                apiKey="3p8k56zhk91j2u468cqpl5fc372vokbjzksn76aeh6a12aek"                  
                onEditorChange={changedcontent => getEditorContent(changedcontent)}
                value={content}
                init={{
                    height: 280,
                    resize: false,
                    max_width: 850,
                    width: "100%",
                    menubar: false,
                    max_chars: 100,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}`
                }}
            />          
        </div>
    );
}
export default CustomEditor;