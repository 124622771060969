import { Button, ButtonProps } from "antd";
import { useNavigate } from "react-router-dom";

interface CustomButtonProps extends ButtonProps {
    title: string;
    to: string; 
}

const CustomButton: React.FC<CustomButtonProps> = ({ title, to,  ...buttonProps }) => {
    const navigate = useNavigate();

    return (
        <Button {...buttonProps} onClick={() => { navigate(to) }} style={{marginRight:"20px"}}>{title}</Button>
    );
}
export default CustomButton;