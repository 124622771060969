import { Space } from 'antd';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import { 
    deleteCareerByIdThunk, 
    getCareersList, 
    getCareersThunk, 
    isCareerDeleted } from '@store/slices/career/career';
import { ICareerResponseData } from '@models/interfaces/career';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import CustomButton from '@components/custom-button';
import { DataType } from '@models/interfaces/table';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';

const CareersTable: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const isDeleted = useAppSelector(isCareerDeleted);
    const careerList = useAppSelector<ICareerResponseData[]>(getCareersList);
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<string | number>();
    
    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: careerList.length // Assuming total count is available in trainingList
    };
    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setId(id);
    };
    const handleDeleteOk = () => {
        setOpen(false);
        dispatch(deleteCareerByIdThunk(id))
    }    
    const handleDeleteCancel = () => setOpen(false)

    useEffect(() => {
        dispatch(getCareersThunk());
    }, [dispatch])

    useEffect(() => {

        if (isDeleted)
            dispatch(getCareersThunk());
    }, [dispatch,isDeleted])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',          
            render: (_, record, index) => {
                return index+1;
            }
        },
        {
            title: t("CAREER.TITLE_LABEL"),
            dataIndex: 'title',
            key: 'title',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t("CAREER.SHORT_DESCRIPTION_LABEL"),
            dataIndex: 'shortDescription',
            key: 'shortDescription',
            render:(_,record,index)=>{
                const sanitizedDescription = DOMPurify.sanitize(record.description as any);
                const descriptionWithoutTags = sanitizedDescription.replace(/<[^>]*>/g, ''); // Remove HTML tags
                return <span dangerouslySetInnerHTML={{ __html: descriptionWithoutTags }} />;

            }
        },      
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/careers/${record.id}`) }} className='link'>{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];

    return (
        <>
            <h3>{t("MAIN.CAREERS")}</h3>
            <CustomButton title={t("MAIN.ADD")} to={"add"} />
            {careerList ? <AdminTable columns={columns} dataSource={careerList} pagination={paginationConfig}/> : "null"}
            <CustomModal title="Are you sure to delete" open={open} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel}/>
        </>

    )
};

export default CareersTable;