import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';

import { useAppDispatch } from '@hooks/index';
import { adminLogout } from '@store/slices';
import logo from "../../assets/logo.svg";
import style from "./layout.module.css";
import { Link } from 'react-router-dom';

const { Header } = Layout;

const AdminHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (

    <Header className={style.header} >
      <div className="logo" >
        <Link to="/"><img src={logo} alt="PADC LLC" className="logo" /></Link>
      </div>
      <div>
        <span onClick={() => {
          dispatch(adminLogout());
        }} className="logout"> <LogoutOutlined />{t("MAIN.LOGOUT")} </span>
      </div>
    </Header>
  );
};
export default AdminHeader;