import { Button, Form, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';

import {
    createProjectThunk,
    getOneProject,
    getProjectByIdThunk,
    getProjectsErrors,
    isProjectCreated,
    isProjectUpdated,
    updateProjectByThunk
} from '@store/slices/projects/projects';
import CustomButton from "@components/custom-button";
import CustomEditor from '@components/custom-editor';

type FieldType = {
    title?: string;
    description?: string;    
};

const Project = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();

    const projectByID = useAppSelector(getOneProject);
    const isCreated = useAppSelector(isProjectCreated);
    const isUpdated = useAppSelector(isProjectUpdated);
    const projectsError = useAppSelector(getProjectsErrors);

    const [editorValue, setEditorValue] = useState<string>("");
    const formValidationRules = {
        title: [{ required: true, message: t('PROJECT.PROJECT_TITLE') }],
        description: [{ required: true, message: t('PROJECT.PROJECT_DESCRIPTION') }],
    };
    
    useEffect(() => {

        if (id)
            dispatch(getProjectByIdThunk(id))
    }, [dispatch,id])

    useEffect(() => {
        form.setFieldsValue({
            title: projectByID?.title,
            description: projectByID?.description
        });
    
        if (isCreated || isUpdated) {
            navigate('/projects/');
        }
    }, [form, projectByID, isCreated, isUpdated, navigate]);

    const getEditorContent = useCallback((content: any) => {
        setEditorValue(content);
   
    }, [setEditorValue]);
    const onFinish = (values: any) => {
        console.log(values)
        const datas = {
            data: {
                'title': values.title,
                'description': editorValue
            },
            'id': id && id,
        };
       id ? dispatch(updateProjectByThunk(datas)) : dispatch(createProjectThunk(datas.data));

    };

    return (
        <>
            <h3>{id ? t("MAIN.EDIT_PROJECT") : t("MAIN.ADD_PROJECT")}</h3>
            <CustomButton title={t("MAIN.BACK")} to={"/projects"} />
            <Form
                name="project"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}                
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                {projectsError ? projectsError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`PROJECT.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                }
                <Form.Item<FieldType>
                    label={t("CAREER.TITLE_LABEL")}
                    name="title"
                    rules={formValidationRules.title}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={t("CAREER.DESCRIPTION_LABEL")}
                    name="description"
                    // rules={formValidationRules.description}
                >
                    <CustomEditor content={editorValue || projectByID?.description} getEditorContent={getEditorContent} />                  
                </Form.Item>             

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="actionButton">
                        {t("MAIN.SAVE")}
                    </Button>
                </Form.Item>
            </Form>
        </>

    );
}
export default Project;