import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { getApplicantByIdThunk, getOneApplicant } from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import CustomButton from "@components/custom-button";
import style from "./applicant.module.css";

const Applicant = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const params: any = useParams();
    const BASE_URL = process.env.REACT_APP_IMAGE_URL;

    const applicantByID = useAppSelector(getOneApplicant);

    useEffect(() => {

        if (params.id)

            dispatch(getApplicantByIdThunk(params.id))

    }, [dispatch, params.id])

    return (
        <>
            <h3>{t("MAIN.APPLICANT")}</h3>
            <CustomButton title={t("MAIN.BACK")} to={"/applicants"} />
            <Row className={style.applicantRow}>
                <Col span={12} ><h3>{t("APPLICANT.NAME")}</h3></Col><Col span={12} >{applicantByID?.fullName}</Col>
                <Col span={12} ><h3>{t("APPLICANT.EMAIL")}</h3></Col><Col span={12} >{applicantByID?.email}</Col>
                <Col span={12} ><h3>{t("APPLICANT.PHONE")}</h3></Col><Col span={12} >{applicantByID?.phone}</Col>
                <Col span={12} ><h3>{t("APPLICANT.ADDRESS")}</h3></Col><Col span={12} >{applicantByID?.address}</Col>
                <Col span={12} ><h3>{t("APPLICANT.CAREER_TITLE")}</h3></Col><Col span={12} >{applicantByID?.vacancy?.title}</Col>
                <Col span={12} ><h3>{t("APPLICANT.CV")}</h3></Col><Col span={12} ><a href={`${BASE_URL}/${applicantByID?.cv?.path}`} target="_blank" rel="noreferrer" >{t("APPLICANT.DOWNLOAD_CV ")}</a></Col>
            </Row>
        </>
    );
}
export default Applicant;