import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createChat, deleteChatById, getChatById, getChats } from "@services/chat.services";


interface ChatState extends IChatResponseData {
  // Additional state properties if needed
}

const initialState: ChatState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  chats: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  chatByID: {},
};

export const getChatsThunk = createAsyncThunk(
  "chats/getChats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getChats();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createChatThunk = createAsyncThunk(
  "chats/createChat",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createChat(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChatByIdThunk = createAsyncThunk(
  "chats/getchatById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getChatById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const updateEventByThunk = createAsyncThunk(
//   "events/updateEventById",
//   async (data: any, { rejectWithValue }) => {
//     try {
//       const response = await updateEventById(data);
//       return Promise.resolve(response);
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
export const deleteChatByIdThunk = createAsyncThunk(
  "chats/deleteChatById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteChatById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChatsThunk.pending, (state: ChatState) => {
      state.loading = true;
    });
    builder.addCase(
      getChatsThunk.fulfilled,
      (state: ChatState, { payload }: PayloadAction<AxiosResponse<any>>) => {        
        state.success = true;
        state.loading = false;
        state.chats = payload.data;
        state.chatByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getChatsThunk.rejected, (state: ChatState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createChatThunk.pending, (state: ChatState) => {
      state.loading = true;
    });
    builder.addCase(
      createChatThunk.fulfilled,
      (state: ChatState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.chatByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createChatThunk.rejected, (state: ChatState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getChatByIdThunk.pending, (state: ChatState) => {
      state.loading = true;
    });
    builder.addCase(
      getChatByIdThunk.fulfilled,
      (state: ChatState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.chatByID = payload.data;
        state.chats = [];
        state.errors = [];
      }
    );
    builder.addCase(getChatByIdThunk.rejected, (state: ChatState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });


    // builder.addCase(updateEventByThunk.pending, (state: EventState) => {
    //   state.loading = true;
    // });
    // builder.addCase(
    //   updateEventByThunk.fulfilled,
    //   (state: EventState, { payload }: PayloadAction<AxiosResponse<any>>) => {
    //     state.success = true;
    //     state.loading = false;
    //     state.isCreated = false;
    //     state.isDeleted = false;
    //     state.isUpdated = true;
    //     state.errors = [];
    //   }
    // );
    // builder.addCase(updateEventByThunk.rejected, (state: EventState, { payload }: PayloadAction<any>) => {
    //   state.message = payload?.message || "";
    //   state.errors = payload.errors;
    //   state.success = false;
    //   state.loading = false;
    //   state.isCreated = false;
    //   state.isDeleted = false;
    //   state.isUpdated = false;

    // });

    builder.addCase(deleteChatByIdThunk.pending, (state: ChatState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteChatByIdThunk.fulfilled,
      (state: ChatState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteChatByIdThunk.rejected, (state: ChatState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getChatList = (state: { chat: ChatState }) => {
  return state.chat?.chats;
};
export const isChatCreated = (state: { chat: ChatState }) => {
  return state.chat?.isCreated;
};
export const isChatDeleted = (state: { chat: ChatState }) => {
  return state.chat?.isDeleted;
};
export const isChatUpdated = (state: { chat: ChatState }) => {
  return state.chat?.isUpdated;
};
export const getOneChat = (state: { chat: ChatState }) => {
  return state.chat?.chatByID;
};
export const getChatsErrors = (state: { chat: ChatState }) => {
  return state.chat?.errors
};