import { Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import React, { useEffect} from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DataType } from '@models/interfaces/table';
import AdminTable from '@components/admin-table';
import { deleteChatByIdThunk, getChatList, getChatsThunk, isChatCreated, isChatDeleted } from '@store/slices/chat/chat';
import { useLocation, useNavigate } from 'react-router-dom';

export const ChatList: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const isDeleted = useAppSelector(isChatDeleted);
    const isCreated = useAppSelector(isChatCreated);
    const chatList = useAppSelector<IChatResponseData[]>(getChatList);

    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: chatList.length // Assuming total count is available in trainingList
    };
    // const [open, setOpen] = useState<boolean>(false);
    // const [id, setId] = useState<string | number>();

    // const showModalDelete = (id?: any): void => {
    //     setOpen(true);
    //     setId(id);
    // };
    // const handleDeleteOk = () => {
    //     setOpen(false);
    //     dispatch(deleteEventByIdThunk(id))
    // }    
    // const handleDeleteCancel = () => setOpen(false);

    useEffect(() => {
        dispatch(getChatsThunk());

    }, [dispatch])

    useEffect(() => {

        if (isDeleted || isCreated)
            dispatch(getChatsThunk());
    }, [dispatch, isDeleted,isCreated])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',
            render: (_, record, index) => {
                return index + 1;
            }
        },
        {
            title: t("EVENT.CHAT_NAME"),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t("EVENT.CHAT_ID"),
            dataIndex: 'chatId',
            key: 'chatId',
        },       
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* <span onClick={() => { navigate(`/events/${record.id}`) }} className='link'>{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span> */}
                    <span onClick={() => dispatch(deleteChatByIdThunk(record.id))} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];
    return (

        <>
            <h3>{t("MAIN.CHAT")}</h3>
            {chatList ? <AdminTable columns={columns} dataSource={chatList} pagination={paginationConfig}/> : null}
            {/* <CustomModal title={t("MAIN.DELETE_MESSAGE")} open={open} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} /> */}
        </>



    )
};