import { Space } from 'antd';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import {
    deleteProjectByIdThunk,
    getProjectsList,
    getProjectsThunk,
    isProjectDeleted
} from '@store/slices/projects/projects';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import CustomButton from '@components/custom-button';
import { DataType } from '@models/interfaces/table';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';

const Projects: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<string | number>();

    const isDeleted = useAppSelector(isProjectDeleted);
    const projectsList = useAppSelector<IProjectsResponseData[]>(getProjectsList);

    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: projectsList.length // Assuming total count is available in trainingList
    };
    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setId(id);
    };
    const handleOk = () => {
        setOpen(false);
        dispatch(deleteProjectByIdThunk(id))
    }

    const handleCancel = () => setOpen(false);

    useEffect(() => {
        dispatch(getProjectsThunk());       
    }, [dispatch])

    useEffect(() => {

        if (isDeleted)
            dispatch(getProjectsThunk());
      
    }, [dispatch,isDeleted])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',          
            render: (_, record, index) => {
                return index+1;
            }
        },
        {
            title: t("CAREER.TITLE_LABEL"),
            dataIndex: 'title',
            key: 'title',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t("CAREER.DESCRIPTION_LABEL"),
            dataIndex: 'description',
            key: 'description',
            render:(_,record,index)=>{
                const sanitizedDescription = DOMPurify.sanitize(record.description as any);
                const descriptionWithoutTags = sanitizedDescription.replace(/<[^>]*>/g, ''); // Remove HTML tags
                return <span dangerouslySetInnerHTML={{ __html: descriptionWithoutTags }} />;

            }
        },    
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/projects/${record.id}`) }} className='link'>{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
         }
    ];

    return (
        <>
            <h3>{t("MAIN.PROJECTS")}</h3>
            <CustomButton title={t("MAIN.ADD")} to={"add"} />
            {projectsList ? <AdminTable columns={columns} dataSource={projectsList && projectsList} pagination={paginationConfig}/> : "null"}
            <CustomModal title={t("MAIN.DELETE_MESSAGE")} open={open} handleOk={handleOk} handleCancel={handleCancel}/>
        </>
    )
};

export default Projects;