import React, { useState } from 'react';
import {
    TeamOutlined,
    AppstoreAddOutlined,
    AuditOutlined,
    StockOutlined,  
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SoundOutlined,
    HddOutlined,
    ReadOutlined   
} from '@ant-design/icons';

import { Button, Layout, Menu, MenuProps, theme } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const items: MenuProps['items'] = [
    {
        key: 'projects',
        icon: <AppstoreAddOutlined />,
        label: 'Projects',
    },
    {
        key: 'teams',
        icon: <TeamOutlined />,
        label: 'Team',
    },
    {
        key: 'trainings',
        icon: <AuditOutlined />,
        label: 'Trainings',
    },
    {
        key: 'careers',
        icon: <StockOutlined />,
        label: 'Career',
    },
    {
        key: 'events',
        icon: <SoundOutlined />,
        label: 'Events',
    },
    // {
    //     key: 'applicants',
    //     icon: <FileSearchOutlined />,
    //     label: 'Applicants',
    // },
    {
        key: 'categories',
        icon: <HddOutlined />,
        label: 'Categories',
      
    },
    {
        key: 'articles',
        icon: <ReadOutlined />,
        label: 'Articles',
      
    },
]

const AdminSider: React.FC = () => {   
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleClick =(info:any)=>{
        navigate(`${info.key}`);
    }
    return (

        <Sider width={260} style={{ background: colorBgContainer }} trigger={null} collapsible collapsed={collapsed}>
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                    float: "right"
                }}
            />
            <Menu
                mode="inline"
                defaultSelectedKeys={['projects']}               
                style={{ height: '100%', borderRight: 0 }}
                items={items}
                onClick={handleClick}
            />         
        </Sider>
    );
};

export default AdminSider;