
import { objectToFormData } from "@utils/helpers";
import { api } from ".";

/*API calls for articles */
export const getArticles= () => {
    return api.get("blog/articles");
}

export const createArticle = (data:any) => {
    return api.post("blog/articles",objectToFormData(data));
}
export const getArticleById = (id:any) => {
    return api.get(`blog/articles/${id}`);
}
export const updateArticleById = (data:any) => {
    return api.patch(`blog/articles/${data.id}`,objectToFormData(data.data));
}
export const deleteArticleById = (id:any) => {
    return api.delete(`blog/articles/${id}`);
}