import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICareerResponseData } from "@models/interfaces/career";
import { createCareer, deleteCareerById, getCareerById, getCareers, updateCareerById } from "@services/career.services";

interface CareerState extends ICareerResponseData {
  // Additional state properties if needed
}

const initialState: CareerState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  careers: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  careerByID: {},
};

export const getCareersThunk = createAsyncThunk(
  "career/getCareer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCareers();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCareerThunk = createAsyncThunk(
  "career/createCareer",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createCareer(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCareerByIdThunk = createAsyncThunk(
  "career/getCareerById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getCareerById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCareerByThunk = createAsyncThunk(
  "career/updateCareerById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateCareerById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCareerByIdThunk = createAsyncThunk(
  "career/deleteCareerById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteCareerById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const CareerSlice = createSlice({
  name: "career",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCareersThunk.pending, (state: CareerState) => {
      state.loading = true;
    });
    builder.addCase(
      getCareersThunk.fulfilled,
      (state: CareerState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.careers = payload.data;
        state.careerByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getCareersThunk.rejected, (state: CareerState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createCareerThunk.pending, (state: CareerState) => {
      state.loading = true;
    });
    builder.addCase(
      createCareerThunk.fulfilled,
      (state: CareerState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.careerByID = {};
        // state.errors = [];
      }
    );
    builder.addCase(createCareerThunk.rejected, (state: CareerState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      // state.errors = payload.errors;
    });


    builder.addCase(getCareerByIdThunk.pending, (state: CareerState) => {
      state.loading = true;
    });
    builder.addCase(
      getCareerByIdThunk.fulfilled,
      (state: CareerState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.careerByID = payload.data;
        state.careers = [];
        // state.errors = [];
      }
    );
    builder.addCase(getCareerByIdThunk.rejected, (state: CareerState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });


    builder.addCase(updateCareerByThunk.pending, (state: CareerState) => {
      state.loading = true;
    });
    builder.addCase(
      updateCareerByThunk.fulfilled,
      (state: CareerState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateCareerByThunk.rejected, (state: CareerState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteCareerByIdThunk.pending, (state: CareerState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteCareerByIdThunk.fulfilled,
      (state: CareerState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteCareerByIdThunk.rejected, (state: CareerState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getCareersList = (state: { career: CareerState }) => {
  return state.career?.careers;
};
export const isCareerCreated = (state: { career: CareerState }) => {
  return state.career?.isCreated;
};
export const isCareerDeleted = (state: { career: CareerState }) => {
  return state.career?.isDeleted;
};
export const isCareerUpdated = (state: { career: CareerState }) => {
  return state.career?.isUpdated;
};
export const getOneCareer = (state: { career: CareerState }) => {
  return state.career?.careerByID;
};
export const getCareersErrors = (state: { career: CareerState }) => {
  return state.career?.errors
};