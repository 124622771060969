import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { deleteApplicantById, getApplicantById, getApplicants } from "@services/applicants.services";
import { IApplicantResponseData } from "@models/interfaces/applicant";


interface ApplicantState extends IApplicantResponseData {
  // Additional state properties if needed
}

const initialState: ApplicantState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  applicants: [],
  isDeleted: false,  
  applicantByID: {},
};

export const getApplicantsThunk = createAsyncThunk(
  "applicant/getApplicant",
  async (data:any, { rejectWithValue }) => {
    try {
      const response = await getApplicants(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getApplicantByIdThunk = createAsyncThunk(
  "applicant/getApplicantById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getApplicantById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteApplicantByIdThunk = createAsyncThunk(
  "applicant/deleteApplicantById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteApplicantById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const ApplicantSlice = createSlice({
  name: "applicant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApplicantsThunk.pending, (state: ApplicantState) => {
      state.loading = true;
    });
    builder.addCase(
      getApplicantsThunk.fulfilled,
      (state: ApplicantState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.applicants = payload.data;
        state.applicantByID = {};        
        state.isDeleted = false;        
      }
    );
    builder.addCase(getApplicantsThunk.rejected, (state: ApplicantState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(getApplicantByIdThunk.pending, (state: ApplicantState) => {
      state.loading = true;
    });
    builder.addCase(
      getApplicantByIdThunk.fulfilled,
      (state: ApplicantState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;       
        state.isDeleted = false;     
        state.applicantByID = payload.data;
        state.applicants = [];
        // state.errors = [];
      }
    );
    builder.addCase(getApplicantByIdThunk.rejected, (state: ApplicantState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;    
      state.isDeleted = false;    
    });

    builder.addCase(deleteApplicantByIdThunk.pending, (state: ApplicantState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteApplicantByIdThunk.fulfilled,
      (state: ApplicantState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;       
        state.isDeleted = true;       
      }
    );
    builder.addCase(deleteApplicantByIdThunk.rejected, (state: ApplicantState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;     
      state.isDeleted = false;     
    });
  },
});

export const getApplicantsList = (state: { applicant: ApplicantState }) => {
  return state.applicant?.applicants;
};
export const isApplicantDeleted = (state: { applicant: ApplicantState }) => {
  return state.applicant?.isDeleted;
};

export const getOneApplicant = (state: { applicant: ApplicantState }) => {
  return state.applicant?.applicantByID;
};
export const getApplicantsErrors = (state: { applicant: ApplicantState }) => {
  return state.applicant?.errors
};