import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';

const resources = {
  'EN': {
    translation: translationEN
  },
};

i18n.use(initReactI18next).init({
    fallbackLng: 'EN',
    resources,
    compatibilityJSON: 'v3',
    lng: 'EN',
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;