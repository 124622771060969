import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTraining, deleteTrainingById, getTrainingById, getTrainings, updateTrainingById } from "@services/trainings.services";

interface TrainingState extends ITrainingResponseData {
  // Additional state properties if needed
}

const initialState: TrainingState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  trainings: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  trainingByID: {},
};

export const getTrainingsThunk = createAsyncThunk(
  "trainings/getTrainings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTrainings();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createTrainingThunk = createAsyncThunk(
  "trainings/createTraining",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createTraining(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTrainingByIdThunk = createAsyncThunk(
  "teams/getMemberById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getTrainingById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTrainingByThunk = createAsyncThunk(
  "trainings/updatetrainingById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateTrainingById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteTraainingsByIdThunk = createAsyncThunk(
  "trainings/deletetrainingbyid",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteTrainingById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const TrainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrainingsThunk.pending, (state: TrainingState) => {
      state.loading = true;
    });
    builder.addCase(
      getTrainingsThunk.fulfilled,
      (state: TrainingState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.trainings = payload.data;
        state.trainingByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getTrainingsThunk.rejected, (state: TrainingState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createTrainingThunk.pending, (state: TrainingState) => {
      state.loading = true;
    });
    builder.addCase(
      createTrainingThunk.fulfilled,
      (state: TrainingState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.trainingByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createTrainingThunk.rejected, (state: TrainingState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload?.errors;
    });


    builder.addCase(getTrainingByIdThunk.pending, (state: TrainingState) => {
      state.loading = true;
    });
    builder.addCase(
      getTrainingByIdThunk.fulfilled,
      (state: TrainingState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.trainingByID = payload.data;
        state.trainings = [];
        state.errors = [];
      }
    );
    builder.addCase(getTrainingByIdThunk.rejected, (state: TrainingState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });


    builder.addCase(updateTrainingByThunk.pending, (state: TrainingState) => {
      state.loading = true;
    });
    builder.addCase(
      updateTrainingByThunk.fulfilled,
      (state: TrainingState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateTrainingByThunk.rejected, (state: TrainingState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteTraainingsByIdThunk.pending, (state: TrainingState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteTraainingsByIdThunk.fulfilled,
      (state: TrainingState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteTraainingsByIdThunk.rejected, (state: TrainingState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getTrainingsList = (state: { training: TrainingState }) => {
  return state.training?.trainings;
};
export const isTrainingCreated = (state: { training: TrainingState }) => {
  return state.training?.isCreated;
};
export const isTrainingDeleted = (state: { training: TrainingState }) => {
  return state.training?.isDeleted;
};
export const isTrainingUpdated = (state: { training: TrainingState }) => {
  return state.training?.isUpdated;
};
export const getOneTraining = (state: { training: TrainingState }) => {
  return state.training?.trainingByID;
};
export const getTrainingErrors = (state: { training: TrainingState }) => {
  return state.training?.errors
};