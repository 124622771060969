import DOMPurify from 'dompurify';
import { Checkbox, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { deleteEventByIdThunk, getEventsList, getEventsThunk, isEventDeleted } from '@store/slices/event/event';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import CustomButton from '@components/custom-button';
import { DataType } from '@models/interfaces/table';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';
import moment from 'moment';

const Events: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const isDeleted = useAppSelector(isEventDeleted);
    const eventsList = useAppSelector<IEventResponseData[]>(getEventsList);

    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<string | number>();
    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: eventsList.length // Assuming total count is available in trainingList
    };
    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setId(id);
    };
    const handleDeleteOk = () => {
        setOpen(false);
        dispatch(deleteEventByIdThunk(id))
    }    
    const handleDeleteCancel = () => setOpen(false);

    useEffect(() => {
        dispatch(getEventsThunk());

    }, [dispatch])

    useEffect(() => {

        if (isDeleted)
            dispatch(getEventsThunk());
    }, [dispatch, isDeleted])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',
            render: (_, record, index) => {
                return index + 1;
            }
        },
        {
            title: t("EVENT.TITLE"),
            dataIndex: 'title',
            key: 'title',
            render: (text) => <span>{text}</span>,
        },
        {
            title: t("EVENT.DESCRIPTION"),
            dataIndex: 'description',
            key: 'description',
            render:(_,record,index)=>{
                const sanitizedDescription = DOMPurify.sanitize(record.description as any);
                const descriptionWithoutTags = sanitizedDescription.replace(/<[^>]*>/g, ''); // Remove HTML tags
                return <span dangerouslySetInnerHTML={{ __html: descriptionWithoutTags }} />;

            }
        },
        {
            title: t("EVENT.AUTHOR"),
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: t("EVENT.LOCATION"),
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: t("EVENT.IS_SCHEDULED"),
            dataIndex: 'isScheduled',
            key: 'isScheduled',
            render: (isScheduled: boolean) => <Checkbox checked={isScheduled} />,
        },
        {
            title: t("EVENT.CHAT_NAME"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("EVENT.DATE_TIME"),
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (_, record, index) => {
                return (moment(record.timestamp).format("YYYY-MM-DD"))

            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/events/${record.id}`) }} className='link'>{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];
    return (

        <>
            <h3>{t("MAIN.EVENTS")}</h3>
            <CustomButton title={t("MAIN.ADD")} to={"add"} />
            {eventsList ? <AdminTable columns={columns} dataSource={eventsList} pagination={paginationConfig}/> : null}
            <CustomModal title={t("MAIN.DELETE_MESSAGE")} open={open} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} />
        </>



    )
};

export default Events;