import { configureStore } from '@reduxjs/toolkit';

import { ApplicantSlice } from '@store/slices/applicant/applicant';
import { ProjectsSlice } from '@store/slices/projects/projects';
import { CareerSlice } from '@store/slices/career/career';
import { AdminLoginSlice, TeamSlice, TrainingSlice } from '..';
import { EventSlice } from '@store/slices/event/event';
import { CategoriesSlice } from '@store/slices/categories/categories';
import { SubCategoriesSlice } from '@store/slices/sub-categories/sub-categories';
import { ArticleSlice } from '@store/slices/article/article';
import { ChatSlice } from '@store/slices/chat/chat';
import { StudentSlice } from '@store/slices/student/student';

 const store = configureStore({
  reducer: {
    adminLogin:AdminLoginSlice.reducer,
    projects:ProjectsSlice.reducer,
    team:TeamSlice.reducer,
    career:CareerSlice.reducer,
    applicant:ApplicantSlice.reducer,
    students:StudentSlice.reducer,
    training:TrainingSlice.reducer,
    event:EventSlice.reducer,
    categories:CategoriesSlice.reducer,
    subCategories:SubCategoriesSlice.reducer,
    article:ArticleSlice.reducer,
    chat:ChatSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;