
import moment from "moment";
import { Space } from "antd";
import DOMPurify from 'dompurify';
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { deleteTraainingsByIdThunk, getTrainingsList, getTrainingsThunk, isTrainingDeleted } from "@store/slices";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import CustomButton from "@components/custom-button";
import { DataType } from "@models/interfaces/table";
import AdminTable from "@components/admin-table";
import CustomModal from "@components/modal";

const TrainingList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<boolean>(false);
    
    const trainingList = useAppSelector(getTrainingsList);
    const isDeleted = useAppSelector(isTrainingDeleted);
    
    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: trainingList.length // Assuming total count is available in trainingList
    };

    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setId(id);
    };
    const handleOk = () => {

        setOpen(false);
        dispatch(deleteTraainingsByIdThunk(id));
    }
    const handleCancel = () => setOpen(false);
    useEffect(() => {
        dispatch(getTrainingsThunk());
    }, [dispatch, isDeleted])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',
            render: (_, record, index) => {
                return index + 1;
            }
        },
        {
            title: t("TRAINING.TITLE"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("TRAINING.DESCRIPTION"),
            dataIndex: 'description',
            key: 'description',
            render: (_, record, index) => {
                const sanitizedDescription = DOMPurify.sanitize(record.description as any);
                const descriptionWithoutTags = sanitizedDescription.replace(/<[^>]*>/g, '');
                const shortText = descriptionWithoutTags.substring(0, 500);
                return <span dangerouslySetInnerHTML={{ __html: shortText }} />;

            }
        },
        {
            title: t("TRAINING.LOCATION"),
            dataIndex: 'location',
            key: 'location',

        },
        {
            title: t("TRAINING.DATE"),
            dataIndex: 'date',
            key: 'date',
            render: (_, record, index) => {
                return (moment(record.date).format("YYYY-MM-DD"))

            }

        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/trainings/${record.id}`) }} className="link">{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className="link">{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];
    return (
        <>
            <h3>{t("MAIN.TRAININGS")}</h3>
            <CustomButton title={t("MAIN.ADD")} to={"add"} />
            <AdminTable columns={columns} dataSource={trainingList && trainingList} pagination={paginationConfig}/>
            <CustomModal title={t("MAIN.DELETE_MESSAGE")} open={open} handleOk={handleOk} handleCancel={handleCancel} />
        </>
    );
}

export default TrainingList;