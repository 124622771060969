import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';

import AdminHeader from './header';
import AdminSider from './sider';
import style from "./layout.module.css"

const AdminLayout: React.FC = () => {

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AdminHeader />
            <Layout>
                <AdminSider />
                <Layout>
                    <Content className={style.content} >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default AdminLayout;