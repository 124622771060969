import _ from 'lodash';
import i18n from '@i18n/index';
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from 'antd';

import { adminLoginThunk, getAdminLoginErrorMessages, getAdminLoginErrors } from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import styles from "./auth.module.css";
import logo from "@assets/logo.svg";

type FieldType = {
  login?: string;
  password?: string;
  remember?: string;
};

const Auth: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorMessages = useAppSelector(getAdminLoginErrorMessages);
  const errors = useAppSelector(getAdminLoginErrors);

  useEffect(() => {
    i18n.changeLanguage('en');
  }, [])

  const onFinish = (values: any) => {
    dispatch(adminLoginThunk(values));
  };

  return (
    <Row className={styles.authRow}>
      <Col span={12} className={styles.formContainer}>
        <img src={logo} alt={t("MAIN.TITLE")} className={styles.logo} />

        {errors?.length && errors?.length > 0 ?
          errors.map((error: any) => {
            return (
              <p className="error">{t(_.toUpper(`AUTH.${error.message}`))} </p>)
          }
          )
          : <p className="error">{errorMessages ? t(_.toUpper(`AUTH.${errorMessages}`)) : null}</p>}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600, margin: "0 auto" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            name="login"
            rules={[{ required: true, message: t("AUTH.LOGIN_REQUIRED") }]}
          >
            <Input placeholder="login" className={styles.authFields} data-testId="login" />
          </Form.Item>

          <Form.Item<FieldType>
            name="password"
            rules={[{ required: true, message: t("AUTH.PASSWORD_REQUIRED") }]}
          >
            <Input.Password placeholder={t("AUTH.PASSWORD")} className={styles.authFields} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" role="button" htmlType="submit" className={styles.authFields} data-testId="buttonid" aria-hidden="true">
              {t("MAIN.LOGIN")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
};

export default Auth;