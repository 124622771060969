
import { Route, Routes } from "react-router-dom";

import AdminLayout from "@components/layout";
import Project from "@pages/projects/project";
import Projects from "@pages/projects";
import Teams from "@pages/team";
import Team from "@pages/team/team";
import Careers from "@pages/career";
import Applicants from "@pages/applicants";
import Applicant from "@pages/applicants/applicant";
import TrainingsIndex from "@pages/trainings";
import TrainingsTabs from "@pages/trainings/components";
import CareerTabs from "@pages/career/career-tab";
import Events from "@pages/events";
import Event from "@pages/events/event";
import Categories from "@pages/category";
import Category from "@pages/category/category";
import Articles from "@pages/article";
import Article from "@pages/article/article";
import Students from "@pages/students";
import Student from "@pages/students/student";

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />} >
      <Route index element={<Projects />} />
        <Route path="projects" >
          <Route index element={<Projects />} />
          <Route path='add' element={<Project />} />
          <Route path=':id' element={<Project />} />
        </Route>
        <Route path="teams" >
          <Route index element={<Teams />} />
          <Route path='add' element={<Team />} />
          <Route path=':id' element={<Team />} />
        </Route>
        <Route path="careers" >
          <Route index element={<Careers />} />
          <Route path='add' element={<CareerTabs/>} />
          <Route path=':id' element={<CareerTabs />} />
        </Route>
        <Route path="applicants" >
          <Route index element={<Applicants />} />
          {/* <Route path='view' element={<Applicant/>} /> */}
          <Route path=':id' element={<Applicant />} />
        </Route>
        <Route path="students" >
          <Route index element={<Students />} />
          {/* <Route path='view' element={<Applicant/>} /> */}
          <Route path=':id' element={<Student />} />
        </Route>
        <Route path="trainings" >
          <Route index element={<TrainingsIndex />} />   
          <Route path='add' element={<TrainingsTabs/>} />
          <Route path=':id' element={<TrainingsTabs/>} />
        </Route>
        <Route path="events" >
          <Route index element={<Events />} />   
          <Route path='add' element={<Event />} />
          <Route path=':id' element={<Event/>} />
        </Route>
        <Route path="categories" >
          <Route index element={<Categories />} />   
          <Route path='add' element={<Category />} />
          <Route path=':id' element={<Category/>} />
          {/* <Route path='add' element={<Category />} />
          <Route path=':id' element={<Category />} /> */}
        </Route>
        <Route path="articles" >
          <Route index element={<Articles />} />   
          <Route path='add' element={<Article />} />
          <Route path=':id' element={<Article/>} />          
        </Route>
        {/* <Route path='*' element={<Navigate to='/' />} /> */}
      </Route>
    </Routes>
  );
};

export default MainRoutes;