import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import Career from './career';
import Applicants from '@pages/applicants';


const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Careers',
    children: <Career/>,
  },
  {
    key: '2',
    label: 'Applicants',
    children: <Applicants />,
  },

];

const CareerTabs: React.FC = () => <Tabs defaultActiveKey="1" items={items}/>;

export default CareerTabs;