import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, Button } from 'antd';

import {
  createCategoryThunk,
  deleteCategoryByIdThunk,
  getCategoriesList,
  getCategoriesThunk,
  isCategoryCreated,
  isCategoryDeleted,
  isCategoryUpdated,
  updateCategoryByThunk
} from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import style from "./category.module.css"

interface Item {
  id: string;
  name: string;
  key: string;
  children?: Item[];
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
  onUpdate: any;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  onUpdate,
  ...restProps
}) => {

  const inputNode = <Input onChange={(ev) => {
    onUpdate(dataIndex, ev.target.value);
  }} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
// Define your custom expand icons
const ExpandIcon = ({ expanded }: { expanded: boolean }) => {
  return (
    <span className="expandIcon">
      {expanded ? '-' : '+'}
    </span>
  );
};



export const CategoryTable: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [editingKey, setEditingKey] = useState('');
  const categoriesList = useAppSelector<any>(getCategoriesList);
  const isUpdated = useAppSelector(isCategoryUpdated);
  const isDeleted = useAppSelector(isCategoryDeleted);
  const idCreated = useAppSelector(isCategoryCreated);

  const [changedRowData, setChangedRowData] = useState<any>({});
  const [addParentId, setAddParentId] = useState<any>();
  const [data, setData] = useState(categoriesList);

  useEffect(() => {
    dispatch(getCategoriesThunk());

  }, [dispatch]);


  useEffect(() => {

    if (categoriesList)
      setData(categoriesList)

  }, [categoriesList]);

  useEffect(() => {

    if (isUpdated || isDeleted || idCreated) {
      dispatch(getCategoriesThunk());
      navigate("/categories");
    }
  }, [dispatch, isUpdated, navigate, isDeleted, idCreated]);

  const expandIcon = ({ expanded, onExpand, record }: any) => {
    if (record.children && record.children.length > 0) {
      return (
        <span onClick={(e) => onExpand(record, e)}>
          <ExpandIcon expanded={expanded} />
        </span>
      );
    }
    return null;
  };


  const handleCellUpdate = (dataIndex: string, updatedValue: string) => {
    setChangedRowData({ ...changedRowData, [dataIndex]: updatedValue })
  };

  const isEditing = (record: Item) => record.id === editingKey;

  const edit = (record: Partial<Item> & { id: React.Key }) => {
    form.setFieldsValue({ name: '', ...record });
    setEditingKey(record.id);
  };

  const removeRow = (record: Item) => {
    const newData = [...data];
    const index = newData.findIndex(item => item.id === record.id);

    if (index > -1) {
      newData.splice(index, 1);
      setData(newData);
    }
  };
  const cancel = (record: Item) => {
    setEditingKey('');
    removeRow(record);
  };

  const save = (id: React.Key, isAddButtonClicked?: boolean) => {
    try {
      const updateddata = { id: id, data: changedRowData }

      if (editingKey && !addParentId) {
        dispatch(updateCategoryByThunk(updateddata));
        setEditingKey('');
      }
      else if (addParentId) {
        dispatch(createCategoryThunk({ parentId: addParentId, ...changedRowData }));
      }
      else {
        dispatch(createCategoryThunk({ ...changedRowData }));
      }

      setData(categoriesList);
      setEditingKey('');

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  // const addNewRow = (record?: Item) => {
  //   const newRow: any = {};
  //   if (record) {
  //     const newData = data.map((item: any) => {
  //       if (item.id === record.id) {
  //         // If it's the parent row, add the new row to its children         
  //         return {
  //           ...item,
  //           children: [...(item.children || []), newRow],
  //         };
  //       } else if (item.children && item.children.length > 0) {
  //         // If it's a child row, update its children recursively
  //         return {
  //           ...item,
  //           children: updateChildren(item.children, record.id, newRow),
  //         };
  //       }
  //       setAddParentId(record.id);
  //       // If it's not the parent or a child row, return the original item
  //       return item;
  //     }
  //     );
  //     setData(newData);
  //     edit(newRow);
  //   }
  //   else {
  //     const newData = [...data];
  //     newData.splice(data.indexOf(record) + 1, 0, newRow);
  //     setAddParentId("");
  //     setData(newData);
  //     edit(newRow);
  //   }
  // };


  const addNewRow = (record?: Item) => {
    const newRow: any = {};
    let newData = [...data];
    if (record) {
      newData = newData.map((item: any,index) => {
        if (item.id === record.id) {
          // If it's the parent row, add the new row to its children
          return {
            ...item,
            children: [...(item.children || []), newRow],
            expanded: true // Expand the parent row if it's collapsed
          };
        } else if (item.children && item.children.length > 0) {
          // If it's a child row, update its children recursively
          return {
            ...item,
            children: updateChildren(item.children, record.id, newRow)
          };
        }
     
        return item;
      });
      
      newData.splice(data.indexOf(record) + 1, 0, newRow);
    }
    else {
      newData.splice(data.indexOf(record) + 1, 0, newRow);
      // newData.push(newRow);
    }

    setAddParentId(record?.id || "");
    setData(newData);
    edit(newRow);
  };



  const updateChildren = (children: Item[], parentId: string, newRow: Item): Item[] => {
    const updatedChildren = cloneDeep(children);

    const findAndUpdate = (items: any) => {
      for (const item of items) {
        if (item.id === parentId) {
          item.children = [...(item.children || []), newRow];
        } else if (item.children && item.children.length > 0) {
          findAndUpdate(item.children);
        }
      }
    };

    findAndUpdate(updatedChildren);
    return updatedChildren;
  };

  const columns = [
    {
      title: t('CATEGORIES.NAME_LABEL'),
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: t('CATEGORIES.KEY_LABEL'),
      dataIndex: 'key',
      width: '15%',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => addParentId ? save(record.id, true) : save(record.id)} style={{ marginRight: 8 }} className={style.links}>
              {t("MAIN.SAVE")}
            </Typography.Link>
            <Popconfirm title={t("CATEGORIES.CONFIRM_CANCEL")} onConfirm={()=>{cancel(record)}} className={style.links}>
              {t("MAIN.CANCEL")}
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }} className={style.links}>
              {t("MAIN.EDIT")}
            </Typography.Link>
            <Typography.Link disabled={editingKey !== ''} onClick={() => addNewRow(record)} style={{ marginRight: 8 }} className={style.links}>
              {t("MAIN.ADD")}
            </Typography.Link>
            <Popconfirm title={t("CATEGORIES.DELETE_CATEGORY")} onConfirm={() => {
              dispatch(deleteCategoryByIdThunk(record.id));
              navigate("/categories")
            }} >
              <span className={style.links}>   {t("MAIN.DELETE")}</span>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onUpdate: handleCellUpdate,
        onAddRow: addNewRow,
      }),
    };
  });

  return (
    <div>
      <Button onClick={(record: any) => addNewRow()} style={{ marginBottom: 16 }}>
        {t("CATEGORIES.ADD_NEW_ROW")}
      </Button>
      {data ?
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            // pagination={{
            //   onChange: ()=>{cancel()},
            // }}
            expandable={{
              expandIcon,
            }}

          />
        </Form>
        : null}

    </div>
  );
};