import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTeam, deleteTeamById, getTeamById, getTeams, updateTeamById } from "@services/team.services";


interface TeamState extends ITeamResponseData {
  // Additional state properties if needed
}

const initialState: TeamState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  projects: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  memberByID: {},
};

export const getTeamsThunk = createAsyncThunk(
  "teams/getTeams",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTeams();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createTeamThunk = createAsyncThunk(
  "teams/createTeam",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createTeam(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTeamByIdThunk = createAsyncThunk(
  "teams/getMemberById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getTeamById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTeamByThunk = createAsyncThunk(
  "teams/updateTeamMemberById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateTeamById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteTeamByIdThunk = createAsyncThunk(
  "teams/deleteTeamById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteTeamById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const TeamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeamsThunk.pending, (state: TeamState) => {
      state.loading = true;
    });
    builder.addCase(
      getTeamsThunk.fulfilled,
      (state: TeamState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.projects = payload.data;
        state.memberByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getTeamsThunk.rejected, (state: TeamState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createTeamThunk.pending, (state: TeamState) => {
      state.loading = true;
    });
    builder.addCase(
      createTeamThunk.fulfilled,
      (state: TeamState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.memberByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createTeamThunk.rejected, (state: TeamState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getTeamByIdThunk.pending, (state: TeamState) => {
      state.loading = true;
    });
    builder.addCase(
      getTeamByIdThunk.fulfilled,
      (state: TeamState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.memberByID = payload.data;
        state.projects = [];
        state.errors = [];
      }
    );
    builder.addCase(getTeamByIdThunk.rejected, (state: TeamState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });


    builder.addCase(updateTeamByThunk.pending, (state: TeamState) => {
      state.loading = true;
    });
    builder.addCase(
      updateTeamByThunk.fulfilled,
      (state: TeamState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateTeamByThunk.rejected, (state: TeamState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteTeamByIdThunk.pending, (state: TeamState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteTeamByIdThunk.fulfilled,
      (state: TeamState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteTeamByIdThunk.rejected, (state: TeamState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getMembersList = (state: { team: TeamState }) => {
  return state.team?.projects;
};
export const isMemberCreated = (state: { team: TeamState }) => {
  return state.team?.isCreated;
};
export const isMemberDeleted = (state: { team: TeamState }) => {
  return state.team?.isDeleted;
};
export const isMemberUpdated = (state: { team: TeamState }) => {
  return state.team?.isUpdated;
};
export const getOneMember = (state: { team: TeamState }) => {
  return state.team?.memberByID;
};
export const getTeamsErrors = (state: { team: TeamState }) => {
  return state.team?.errors
};