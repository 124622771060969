import { Select } from "antd";
import { useEffect, useState } from "react";

import { getCategoriesList, getCategoriesThunk } from "@store/slices";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { flattenArray } from "@utils/helpers";

const { Option } = Select;

const SelectList = ({ setCategoryId, selectedCategoryId }: any) => {
    const dispatch = useAppDispatch();

    const categoriesList = useAppSelector<any>(getCategoriesList);
    const flattenedCategoriesList = flattenArray(categoriesList);
    const [selectedValue, setSelectedValue] = useState<number | undefined>(selectedCategoryId);

    useEffect(() => {
        dispatch(getCategoriesThunk());

    }, [dispatch]);
    console.log(selectedCategoryId, "selectedCategoryId")

    const renderOptions = (categories: ICategory[] | undefined) => {

        if (!categories || categories.length === 0) {
            return null;
        }
        return categories.map((category) => (
            <Option key={category.id} value={category.id}>
                {category.name}
            </Option>
        ));
    };

    return (
        <Select
            style={{ width: "100%" }}
            onChange={(value) => {
                setCategoryId(value);
                setSelectedValue(value);
            }}
            value={selectedCategoryId ? selectedCategoryId : selectedValue}
        >
            {renderOptions(flattenedCategoriesList)}
        </Select>

    );
};

export default SelectList;
