import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import { deleteTeamByIdThunk, getMembersList, getTeamsThunk, isMemberDeleted } from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DataType } from '@models/interfaces/table';
import CustomButton from '@components/custom-button';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';

const Teams: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const  isDeleted = useAppSelector(isMemberDeleted);
    const membersList = useAppSelector<ITeamResponseData[]>(getMembersList);

    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<number | string>();
   
    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: membersList.length // Assuming total count is available in trainingList
    };
    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setId(id);
    };
    const handleDeleteOk = () => {

        setOpen(false);
        dispatch(deleteTeamByIdThunk(id))
    }
    const handleDeleteCancel = () => setOpen(false);

    useEffect(() => {
        dispatch(getTeamsThunk());
    }, [dispatch])

    useEffect(()=>{

         if(isDeleted)

         dispatch(getTeamsThunk());

    },[dispatch,isDeleted])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',          
            render: (_, record, index) => {
                return index+1;
            }
        },
        {
            title: t("TEAM.TEAM_NAME_LABEL"),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>,
        },
        {
            title:  t("TEAM.TEAM_SURNAME_LABEL"),
            dataIndex: 'surname',
            key: 'surname',
        },
        {
            title:  t("TEAM.TEAM_ROLE_LABEL"),
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/teams/${record.id}`) }} className='link'>{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];
   
    return (
        <>
            <h3>{t("MAIN.TEAMS")}</h3>
            <CustomButton title={t("MAIN.ADD")} to={"add"} />
            {membersList ? <AdminTable columns={columns} dataSource={membersList} pagination={paginationConfig}/> : "null"}
            <CustomModal title={t("MAIN.DELETE")} open={open} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel}/>
        </>

    )
};

export default Teams;