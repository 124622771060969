
import { ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Routing from './routes';
import "./App.css";

const locals = {
  components: {
    Button: {
      colorPrimary: '#3DA2C2',
      colorPrimaryActive: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2",
    },
    Input: {
      colorPrimary: '#3DA2C2',
      colorPrimaryHover: "#3DA2C2"
    },
    Checkbox: {
      colorPrimary: "#fff",
      colorPrimaryBorder: "none",
    },
    Menu: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    },
    DatePicker: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    },
    Select: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    },
    Radio: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    },
    Tabs: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    },
    Pagination: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    },
    Switch: {
      colorPrimary: "#3DA2C2",
      colorPrimaryHover: "#3DA2C2"
    }
  }
};
const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider theme={locals}>

        <div className="App">
          <Routing />
        </div>

      </ConfigProvider>
    </DndProvider>
  );
}

export default App;
