import { RcFile } from "antd/es/upload";

export function objectToFormData(obj: Record<string, string | File>): FormData {
    const formData = new FormData();
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
        }
    }
    return formData;
}




export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            if (e.target?.result) {
                resolve(e.target.result as string);
            }
        }
        reader.onerror = error => reject(error);
    });

    export const flattenArray = (arr: any) => {
        let result: any = [];
    
        arr.forEach((item: any) => {
            result.push({
                id: item.id,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                name: item.name,
                key: item.key,
            });
    
            if (item.children && item.children.length > 0) {
                const flattenedChildren = flattenArray(item.children);
                result = result.concat(flattenedChildren);
            }
        });
    
        return result;
    };