import { api } from ".";

/*API calls for Training */
export const getTrainings = () => {
    return api.get("trainings");
}

export const createTraining = (data:FormData) => { 
    return api.post("trainings", data);
}

export const getTrainingById = (id:any) => {
    return api.get(`trainings/${id}`);
}
export const updateTrainingById = (data:any) => {  
    return api.patch(`trainings/${data.id}`, data.formData);
}
export const deleteTrainingById = (id:any) => {
    return api.delete(`trainings/${id}`);
}