
import { api } from ".";

/*API calls for Events */
export const getEvents = () => {
    return api.get("events");
}
export const createEvent = (data:any) => {     
    return api.post("events", data);
}
export const getEventById = (id:any) => {
    return api.get(`events/${id}`);
}
export const updateEventById = (data:any) => {
    return api.put(`events/${data.id}`,data.data);
}
export const deleteEventById = (id:any) => {
    return api.delete(`events/${id}`);
}