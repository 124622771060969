import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';

import {
    deleteArticleByIdThunk, 
    getArticlesList, 
    getArticlesThunk, 
    isArticleDeleted } from '@store/slices';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DataType } from '@models/interfaces/table';
import CustomButton from '@components/custom-button';
import AdminTable from '@components/admin-table';
import CustomModal from '@components/modal';
import DOMPurify from 'dompurify';

const Articles: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    const  isDeleted = useAppSelector(isArticleDeleted);
    const articlesList = useAppSelector<IArticleResponseData[]>(getArticlesList);

    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<boolean>(false);

    const handlePageChange = (page: number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', String(page));
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
    const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
        pageSize: 10, // Adjust as per your requirement
        total: articlesList.length // Assuming total count is available in trainingList
    };

    const showModalDelete = (id?: any): void => {
        setOpen(true);
        setId(id);
    };
    const handleOk = () => {

        setOpen(false);
        dispatch(deleteArticleByIdThunk(id))
    }
    const handleCancel = () => setOpen(false);

    useEffect(() => {
        dispatch(getArticlesThunk());
    }, [dispatch])

    useEffect(()=>{

         if(isDeleted)

         dispatch(getArticlesThunk());

    },[dispatch,isDeleted])

    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',          
            render: (_, record, index) => {
                return index+1;
            }
        },
        {
            title: t("ARTICLE.TITLE_LABEL"),
            dataIndex: 'title',
            key: 'title',
            render: (text) => <span>{text}</span>,
        },
        {
            title:  t("ARTICLE.DESCRIPTION_LABEL"),
            dataIndex: 'description',
            key: 'description',
            render:(_,record, index)=>{
                const sanitizedDescription = DOMPurify.sanitize(record.description as any);
                const descriptionWithoutTags = sanitizedDescription.replace(/<[^>]*>/g, ''); // Remove HTML tags
                return <span dangerouslySetInnerHTML={{ __html: descriptionWithoutTags }} />;

            }
        },
        {
            title:  t("ARTICLE.AUTHOR_LABEL"),
            dataIndex: 'author',
            key: 'author',
        },
        {
            title:  t("ARTICLE.CATEGORY_LABEL"),
            dataIndex: 'category',
            key: 'category',
            render: (_, record:any) => {
                return record.category.name;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span onClick={() => { navigate(`/articles/${record.id}`) }} className='link'>{t("MAIN.EDIT")}</span>
                    <span onClick={() => showModalDelete(record.id)} className='link'>{t("MAIN.DELETE")}</span>
                </Space>
            ),
        },
    ];
   
    return (
        <>
            <h3>{t("MAIN.ARTICLES")}</h3>
            <CustomButton title={t("MAIN.ADD")} to={"add"} />
            {articlesList ? <AdminTable columns={columns} dataSource={articlesList && articlesList} pagination={paginationConfig}/> : "null"}
            <CustomModal title={t("MAIN.DELETE")} open={open} handleOk={handleOk} handleCancel={handleCancel}/>
        </>

    )
};

export default Articles;