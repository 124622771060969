import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { IApplicantResponseData } from '@models/interfaces/applicant';
import { IStudentResponseData } from '@models/interfaces/student';
import { ICareerResponseData } from '@models/interfaces/career';
import { DataType } from '@models/interfaces/table';

const AdminTable: React.FC<{ 
    columns: ColumnsType<DataType>, 
    dataSource:
        IProjectsResponseData[] |
        ITeamResponseData[] | 
        ICareerResponseData[] | 
        IApplicantResponseData[] | 
        IEventResponseData[] |
        ICategoriesResponseData[] |
        IArticleResponseData[] |
        IChatResponseData[] |
        IStudentResponseData[],
        pagination:any
    }
   > = ({ columns, dataSource, pagination }: any) => {
    return (
        <Table columns={columns} dataSource={dataSource} pagination={pagination}/>
    )
};

export default AdminTable;