
import { api } from ".";

/*API calls for categories */
export const getCategories= () => {
    return api.get("blog/categories");
}

export const createCategory = (data:any) => {
    return api.post("blog/categories",data);
}
export const getCategoryById = (id:any) => {
    return api.get(`blog/categories/${id}`);
}
export const updateCategoryById = (data:any) => {
    return api.patch(`blog/categories/${data.id}`,data.data);
}
export const deleteCategoryById = (id:any) => {
    return api.delete(`blog/categories/${id}`);
}