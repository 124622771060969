import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { 
  deleteProjectById, 
  updateProjectById,  
  getProjectById, 
  createProject,
  getProjects, 
  } from "@services/projects.service";

interface ProjectsState extends IProjectsResponseData {
  // Additional state properties if needed
}

 const initialState: ProjectsState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  projects: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  projectByID: {},
};

export const getProjectsThunk = createAsyncThunk(
  "projects/getProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProjects();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createProjectThunk = createAsyncThunk(
  "projects/createProject",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createProject(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProjectByIdThunk = createAsyncThunk(
  "projects/getProjectById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getProjectById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProjectByThunk = createAsyncThunk(
  "projects/updateProjectById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateProjectById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteProjectByIdThunk = createAsyncThunk(
  "projects/deleteProjectById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteProjectById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const ProjectsSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectsThunk.pending, (state: ProjectsState) => {
      state.loading = true;
    });
    builder.addCase(
      getProjectsThunk.fulfilled,
      (state: ProjectsState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.projects = payload.data;
        state.projectByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getProjectsThunk.rejected, (state: ProjectsState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createProjectThunk.pending, (state: ProjectsState) => {
      state.loading = true;
    });
    builder.addCase(
      createProjectThunk.fulfilled,
      (state: ProjectsState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.projectByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createProjectThunk.rejected, (state: ProjectsState, { payload }: PayloadAction<any>) => {   
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getProjectByIdThunk.pending, (state: ProjectsState) => {
      state.loading = true;
    });
    builder.addCase(
      getProjectByIdThunk.fulfilled,
      (state: ProjectsState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.projectByID = payload.data;
        state.projects = [];
        state.errors = [];
      }
    );
    builder.addCase(getProjectByIdThunk.rejected, (state: ProjectsState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });

    builder.addCase(updateProjectByThunk.pending, (state: ProjectsState) => {
      state.loading = true;
    });
    builder.addCase(
      updateProjectByThunk.fulfilled,
      (state: ProjectsState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateProjectByThunk.rejected, (state: ProjectsState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteProjectByIdThunk.pending, (state: ProjectsState) => {
      state.loading = true;
    });
    
    builder.addCase(
      deleteProjectByIdThunk.fulfilled,
      (state: ProjectsState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteProjectByIdThunk.rejected, (state: ProjectsState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getProjectsList = (state: { projects: ProjectsState }) => {
  return state.projects?.projects;
};
export const isProjectCreated = (state: { projects: ProjectsState }) => {
  return state.projects?.isCreated;
};
export const isProjectDeleted = (state: { projects: ProjectsState }) => {
  return state.projects?.isDeleted;
};
export const isProjectUpdated = (state: { projects: ProjectsState }) => {
  return state.projects?.isUpdated;
};
export const getOneProject = (state: { projects: ProjectsState }) => {
  return state.projects?.projectByID;
};
export const getProjectsErrors = (state: { projects: ProjectsState }) => {
  return state.projects?.errors
};