
import { api } from ".";

/*API calls for  sub categories */
export const getSubCategories= () => {
    return api.get("blog/sub-category");
}

export const createSubCategory = (data:any) => {
    return api.post("blog/sub-category",data);
}
export const getSubCategoryById = (id:any) => {
    return api.get(`blog/sub-category/${id}`);
}
export const updateSubCategoryById = (data:any) => {
    return api.patch(`blog/sub-category/${data.id}`,data.data);
}
export const deleteSubCategoryById = (id:any) => {
    return api.delete(`blog/sub-category/${id}`);
}