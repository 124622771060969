import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Button, Form, Input, Upload, UploadProps } from 'antd';

import {
    createTeamThunk,
    getOneMember,
    getTeamByIdThunk,
    getTeamsErrors,
    isMemberCreated,
    isMemberUpdated,
    updateTeamByThunk
} from '@store/slices';
import CustomButton from "@components/custom-button";
import style from "./team.module.css";

type FieldType = {
    name?: string;
    surname?: string;
    position?: string;
    role?: string;
    height?: string
    image:any;
};

const Team = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const BASE_URL = process.env.REACT_APP_IMAGE_URL;

    const memberByID = useAppSelector(getOneMember);
    const isCreated = useAppSelector(isMemberCreated);
    const isUpdated = useAppSelector(isMemberUpdated);
    const teamsError = useAppSelector(getTeamsErrors);

    const [image, setImage] = useState<any>();

    useEffect(() => {

        if (id)
            dispatch(getTeamByIdThunk(id))
    }, [dispatch,id])

    useEffect(() => {

        if (isCreated || isUpdated)
            navigate(`/teams/`)
    }, [navigate,isCreated, isUpdated])

    useEffect(() => {
        form.setFieldsValue({
            name: memberByID?.name,
            surname: memberByID?.surname,
            role: memberByID?.role,
            height: memberByID?.height,
            position: memberByID?.position,
            file:memberByID?.image?.path
        });
    }, [form,memberByID]);

    const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        accept: "image/*",
        beforeUpload: () => {
            return false;
        },
        onChange(info) {        
            const file = info.fileList.length ? info.file : undefined;
            setImage(file as RcFile)
        },
    };

    const onFinish = (values: any) => {

        const datas: any = {
            data: {
                "name": values.name,
                "surname": values.surname,
                "position": values.position,
                "role": values.role,
                "height": values.height,
                "file": image
            },
            "id": id && id,
        }
       id ? dispatch(updateTeamByThunk(datas)) : dispatch(createTeamThunk(datas.data));
    };

    return (
        <>
            <h3>{id ? t("MAIN.EDIT_TEAM") : t("MAIN.ADD_TEAM")}</h3>
            <CustomButton title={t("MAIN.BACK")} to={"/teams"} />
            <Form
                name="team"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}              
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                {teamsError ? teamsError.map((errorText: any, index) => {
                    return <p key={index} className="error">{t(`TEAM.${errorText.message.toUpperCase()}`)}</p>
                })
                    : null
                }
                <Form.Item<FieldType>
                    label={t("TEAM.TEAM_NAME_LABEL")}
                    name="name"
                    rules={[{ required: true, message: t("TEAM.TEAM_NAME") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={t("TEAM.TEAM_SURNAME_LABEL")}
                    name="surname"
                    rules={[{ required: true, message: t("TEAM.TEAM_SURNAME") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("TEAM.TEAM_ROLE_LABEL")}
                    name="role"
                    rules={[{ required: true, message: t("TEAM.TEAM_ROLE") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("TEAM.TEAM_POSITION_LABEL")}
                    name="position"
                   
                >
                    <Input placeholder='ex. -50px -10px' />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("TEAM.TEAM_HEIGHT_LABEL")}
                    name="height"
                   
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={t("TEAM.TEAM_IMAGE_LABEL")}
                    name="image"
                    rules={[{ required: true, message: t("TEAM.TEAM_IMAGE") }]}
                >
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>{t("MAIN.UPLOAD")}</Button>
                    </Upload>                    
                </Form.Item>
                {memberByID?.image?.path ? <img src={`${BASE_URL}/${memberByID?.image?.path}`} className={style.image} alt ={memberByID?.image?.path} /> : null}
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className="actionButton">
                        {t("MAIN.SAVE")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
export default Team;