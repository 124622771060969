
import { objectToFormData } from "@utils/helpers";
import { api } from ".";

/*API calls for Team */
export const getTeams = () => {
    return api.get("team");
}
export const createTeam = (data:any) => {     
    return api.post("team", objectToFormData(data));
}
export const getTeamById = (id:any) => {
    return api.get(`team/members/${id}`);
}
export const updateTeamById = (data:any) => {
    return api.patch(`team/members/${data.id}`, objectToFormData(data.data));
}
export const deleteTeamById = (id:any) => {
    return api.delete(`team/members/${id}`);
}