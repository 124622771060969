import { Button, Col, Row } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from '@hooks/index';
import style from "./student.module.css";
import { getOneStudent, getStudentByIdThunk } from '@store/slices/student/student';

const Student = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params: any = useParams();

    const studentByID = useAppSelector(getOneStudent);

    useEffect(() => {

        if (params.id)
            dispatch(getStudentByIdThunk(params.id))
    }, [dispatch, params.id])

    const renderColumns = () => {
        if (!studentByID?.data) return null;

        return Object.keys(studentByID?.data).map(key => (
            <Row className={style.applicantRow} key={key}>
                <Col span={12}><h3>{key.toUpperCase()}</h3></Col>
                <Col span={12}>{studentByID.data[key]}</Col>
            </Row>
        ));
    };

    return (
        <>
            <h3>{t("MAIN.APPLICANT")}</h3>
            <Button onClick={() => (navigate(-1))}>{t("MAIN.BACK")}</Button>
            {renderColumns()}
        </>
    );
}
export default Student;