import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createArticle, deleteArticleById, getArticleById, getArticles, updateArticleById } from "@services/articles.service";


interface ArticleState extends IArticleResponseData {
  // Additional state properties if needed
}

const initialState: ArticleState = {
  success: false,
  message: "",
  errors: [],
  statusCode: 0,
  statusName: "",
  loading: false,
  articles: [],
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  articleByID: {},
};

export const getArticlesThunk = createAsyncThunk(
  "articles/getArticles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getArticles();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createArticleThunk = createAsyncThunk(
  "articles/createArticle",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createArticle(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getArticleByIdThunk = createAsyncThunk(
  "articles/getArticleById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getArticleById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateArticleByThunk = createAsyncThunk(
  "articles/updateTeamMemberById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateArticleById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteArticleByIdThunk = createAsyncThunk(
  "articles/deleteArticleById",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteArticleById(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const ArticleSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArticlesThunk.pending, (state: ArticleState) => {
      state.loading = true;
    });
    builder.addCase(
      getArticlesThunk.fulfilled,
      (state: ArticleState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.articles = payload.data;
        state.articleByID = {};
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
      }
    );
    builder.addCase(getArticlesThunk.rejected, (state: ArticleState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
    });

    builder.addCase(createArticleThunk.pending, (state: ArticleState) => {
      state.loading = true;
    });
    builder.addCase(
      createArticleThunk.fulfilled,
      (state: ArticleState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = true;
        state.isDeleted = false;
        state.isUpdated = false;
        state.articleByID = {};
        state.errors = [];
      }
    );
    builder.addCase(createArticleThunk.rejected, (state: ArticleState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
      state.errors = payload.errors;
    });


    builder.addCase(getArticleByIdThunk.pending, (state: ArticleState) => {
      state.loading = true;
    });
    builder.addCase(
      getArticleByIdThunk.fulfilled,
      (state: ArticleState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = false;
        state.articleByID = payload.data;
        state.articles = [];
        state.errors = [];
      }
    );
    builder.addCase(getArticleByIdThunk.rejected, (state: ArticleState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });


    builder.addCase(updateArticleByThunk.pending, (state: ArticleState) => {
      state.loading = true;
    });
    builder.addCase(
      updateArticleByThunk.fulfilled,
      (state: ArticleState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = false;
        state.isUpdated = true;
        state.errors = [];
      }
    );
    builder.addCase(updateArticleByThunk.rejected, (state: ArticleState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;

    });

    builder.addCase(deleteArticleByIdThunk.pending, (state: ArticleState) => {
      state.loading = true;
    });

    builder.addCase(
      deleteArticleByIdThunk.fulfilled,
      (state: ArticleState, { payload }: PayloadAction<AxiosResponse<any>>) => {
        state.success = true;
        state.loading = false;
        state.isCreated = false;
        state.isDeleted = true;
        state.isUpdated = false;
      }
    );
    builder.addCase(deleteArticleByIdThunk.rejected, (state: ArticleState, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errors = payload.errors;
      state.success = false;
      state.loading = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.isUpdated = false;
    });
  },
});

export const getArticlesList = (state: { article: ArticleState }) => {
  return state.article?.articles;
};
export const isArticleCreated = (state: { article: ArticleState }) => {
  return state.article?.isCreated;
};
export const isArticleDeleted = (state: { article: ArticleState }) => {
  return state.article?.isDeleted;
};
export const isArticleUpdated = (state: { article: ArticleState }) => {
  return state.article?.isUpdated;
};
export const getOneArticle = (state: { article: ArticleState }) => {
  return state.article?.articleByID;
};
export const getArticlesErrors = (state: { article: ArticleState }) => {
  return state.article?.errors
};