
import { api } from ".";

/*API calls for Applicants */
export const getApplicants = (data:any) => {
    // return api.get("vacancies/applicants",
    //    { params: { vacancyId: data } }
    //    );
    if(data){
    return api.get(`vacancies/${data}/applicants`)
    }
    else{
        return api.get(`vacancies/applicants`)
    }
}
export const getApplicantById = (id:any) => {
    return api.get(`vacancies/applicants/${id}`);
}
export const deleteApplicantById = (id:any) => {
    return api.delete(`vacancies/applicants/${id}`);
}