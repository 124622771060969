
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, MinusCircleOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { FieldType } from "@utils/consts";
import style from "../training.module.css";

const DynamicForm = ({
  key,
  name,
  handleTypeChange,
  form,
  selectedTypes,
  handleCheck,
  showValidationState,
  remove,
  move,
  index,
  fields
}: any) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [formsData, setFormsData] = useState<any>();
  const [isFormVisible, setIsFormVisible] = useState(true); // Add state for form visibility

  const requireOptionTypes = ["select", "checkbox", "radio"];
  const isInputType = selectedTypes[name] === 'input' || formsData?.training[name]?.type === 'input';
  const isFileType = selectedTypes[name] === 'file' || formsData?.training[name]?.type === 'file';
  useEffect(() => {
    const values = form.getFieldsValue(['training']);
    setFormsData(values);

  }, [form])
  const handleFormClose = () => {
    // Add any additional logic needed before closing the form
    setIsFormVisible(false);
  };

  // Conditionally render the form based on visibility state
  if (!isFormVisible) {
    return (
      <div className={style.openForm}>
        <span onClick={() => setIsFormVisible(true)} >{formsData?.training[name]?.name} <CaretUpOutlined /></span>
      </div>
    );
  }
  return (
    <Row key={key} gutter={16} className={style.formRow}>
      <Col span={24}>
        <span onClick={handleFormClose} className={style.closeForm}><CaretDownOutlined /></span>
      </Col>
      <Col span={8}>
        <Form.Item
          name={[name, 'name']}
          rules={[{ required: true, message: t("TRAINING.FIELD_REQUIRED") }]}
          label={t("TRAINING.FIELD_NAME")}
        >
          <Input placeholder={t("TRAINING.FIELD_NAME")} />
        </Form.Item>

      </Col>
      <Col span={8}>
        <Form.Item
          name={[name, 'type']}
          rules={[{ required: true, message: t("TRAINING.FIELD_TYPE_REQUIRED") }]}
          label={t("TRAINING.FIELD_TYPE")}
        >
          <Select placeholder={t("TRAINING.CHOOSE_FIELD_TYPE")} onChange={(value: any) => handleTypeChange(name, value)}>
            <Option value="input">{FieldType.Input}</Option>
            <Option value="select">{FieldType.Select}</Option>
            <Option value="radio">{FieldType.Radio}</Option>
            <Option value="checkbox">{FieldType.Checkbox}</Option>
            <Option value="textarea">{FieldType.Textarea}</Option>
            <Option value="file">{FieldType.File}</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={[name, 'placeholder']}
          rules={[{ required: true, message: t("TRAINING.FIELD_PLACEHOLDER_REQUIRED") }]}
          label={t("TRAINING.PLACEHOLDER_LABEL")}
        >
          <Input placeholder={t("TRAINING.FIELD_PLACEHOLDER")} />
        </Form.Item>
      </Col>
      {(requireOptionTypes.includes(selectedTypes[name])) || (formsData?.training && formsData?.training[name] && formsData?.training[name]?.select) ? (
        <Col span={24}>
          <Form.Item label="List">
            <Form.List name={[name, 'select']}>
              {(subFields: any, subOpt: any) => (
                <div className={style.subFieldsDiv}>
                  {subFields.map((subField: any) => (
                    <Space key={subField.key}>
                      <Col span={18}>
                        <Form.Item noStyle name={subField.name}>
                          <Input placeholder={t("TRAINING.FIELD_OPTION")} />
                        </Form.Item>
                      </Col>
                      <Col span={6} >
                        <CloseOutlined
                          onClick={() => {
                            subOpt.remove(subField.name);
                          }}
                        />
                      </Col>
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => subOpt.add()} block>
                    + {t("TRAINING.ADD_FIELD_OPTION")}
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item name={[name, 'showValidation']} label={t("TRAINING.SHOW_VALIDATION")} valuePropName="checked">
          <Checkbox onChange={(e: any) => { handleCheck(name, e.target.checked) }} />
        </Form.Item>
      </Col>

      {showValidationState[name] || (formsData?.training && formsData?.training[name] && formsData?.training[name]?.showValidation) ? (
        <>
          <Col span={6}>
            <Form.Item name={[name, 'required']} label="Required" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          {isInputType && (
            <>
              <Col span={9}>
                <Form.Item name={[name, 'max']} label={t("TRAINING.MAX_FIELD")}>
                  <Input type='number' />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name={[name, 'min']} label={t("TRAINING.MIN_FIELD")}>
                  <Input type='number' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name={[name, 'pattern']} label={t("TRAINING.PATTERN_FIELD")}>
                  <Input />
                </Form.Item>
              </Col>
            </>
          )}
          {isFileType ? (<>
            <Col span={9}>
              <Form.Item
                name={[name, 'size']}
                label={t("TRAINING.MAX_SIZE_FIELD")}
                extra={t("TRAINING.MAX_SIZE_HINT")}
              >
                <Input type='number' />
              </Form.Item>
              {/* <span className={style.hint} >{t("TRAINING.MAX_SIZE_HINT")}</span> */}

            </Col>
            <Col span={24}>
              <Form.Item
                name={[name, 'ext']}
                label={t("TRAINING.EXTENTION_FIELD")}
                extra={t("TRAINING.EXT_HINT")}>
                <Input />
              </Form.Item>
              {/* <span className={style.hint}>{t("TRAINING.EXT_HINT")}</span> */}
            </Col>
          </>) : null}
        </>
      ) : null}



      <Col span={24}>
        <Button
          type="text"
          icon={<ArrowUpOutlined />}
          disabled={index === 0} // Disable if it's the first item
          onClick={() => {
            move(index, index - 1);
          }
          }
        />
        <Button
          type="text"
          icon={<ArrowDownOutlined />}
          disabled={index === fields.length - 1} // Disable if it's the last item
          onClick={() => {
            move(index, index + 1);
          }}
        />
        <MinusCircleOutlined onClick={() => remove(name)} />
      </Col>
    </Row>
  )
}
export default DynamicForm;